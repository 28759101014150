/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Badge,
  Tab,
  Tabs,
  Typography,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Modal,
  Popover,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import GetAppIcon from "@mui/icons-material/GetApp";
import DeleteIcon from "@mui/icons-material/Delete";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import DummyPDF from "../../../assets/dummy.pdf";
import { current } from "@reduxjs/toolkit";
import { useCatch } from "../../../reactHelper";

const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(10),
  marginRight: theme.spacing(1),
  color: theme.palette.text.secondary,
  "&.Mui-selected": {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main,
  },
}));

const getFile = (documentId, fileName) => {
  console.log(`getfile ${documentId}, fileName ${fileName}`);
  fetch(`/api/documents/view/${documentId}`)
    .then((res) => res.blob())
    .then((data) => {
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.download = fileName;
      a.click();
    });
};

  function TruckDocuments() {
    const theme = useTheme();
    const navigate = useNavigate();
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [devices, setDevices] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState({
      id: "",
      expirationDate: "",
      deviceId: "",
      fileName: "",
    });

    const [selectedDevice, setSelectedDevice] = useState({});

    const userId = useSelector((state) => state.session.user.id);
    // const imBox1 = useRef(null);

    const [imgBlob, setImage] = useState("");
    const showImage = (documentId) => {
      // const imageBox = getElementById("imageBox");
      // const imageBox = React.findDOMNode(this.refs.cpDev1).value;
      console.log(`show image ${documentId}`);
      fetch(`/api/documents/view/${documentId}`)
        .then((res) => res.blob())
        .then((data) => {
          setImage(URL.createObjectURL(data));
          const img = document.createElement("img");
          img.src = URL.createObjectURL(data);
          img.onload = () => {
            URL.revokeObjectURL(img.src);
          };
          // imBox1.current.appendChild(img);
          // imageBox.appendChild(img);
        });
    };

    useEffect(() => {
      console.log("print userId")
      const fetchData = async () => {
        const response = await fetch(`/api/documents/viewAllDoc/${userId}`);
        const data = await response.json();
        setDocuments(data);
      };
      fetchData();
    }, [userId]);

    useEffect(() => {
      console.log("print empty")
      const fetchData = async () => {
        const response = await fetch(`/api/devices`);
        const data = await response.json();
        setDevices(data);
      };
      fetchData();
    }, []);

    const getDocumentsForDevice = (deviceId) => {
      console.log("getDocumentsForDevice")
      return documents.filter(
        (document) => document.deviceId === deviceId && document.permanent
      );
    };

    const handleViewModalOpen = (document) => {
      console.log(document);
      setSelectedDocument(document);
      showImage(document.id);
      setViewModalOpen(true);
    };

    // useEffect(() => {
    //   console.log(selectedDocument);
    // }, [selectedDocument]);

    const handleViewModalClose = () => {
      setViewModalOpen(false);
    };

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [newExpirationDate, setNewExpirationDate] = useState("");

    const handleEditModalOpen = () => {
      setEditModalOpen(true);
    };

    const handleEditModalClose = () => {
      setEditModalOpen(false);
    };
    //Update the expiration date with this code
    const handleExpirationDateChange = (event) => {
      setNewExpirationDate(event.target.value);
    };

    const handleEditExpirationDate = async (doc_id) => {
      console.log("New exp date", newExpirationDate);
      if (doc_id != "") {
        // Make API call to update the expiration date for the given document ID
        try {
          const response = await fetch(`/api/documents/edit/${doc_id}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newExpirationDate),
          });

          if (!response.ok) {
            throw new Error("Failed to update expiration date");
          }

          console.log("Expiration date updated successfully");
          setNewExpirationDate("");
          handleEditModalClose();
        } catch (error) {
          console.error(error);
        }
      }
    };

    const downloadPdf = () => {
      // Create a new Blob object with the PDF data
      const pdfBlob = new Blob(
        [
          /* PDF data */
        ],
        { type: "application/pdf" }
      );

      // Create a download link for the PDF file
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const downloadLink = document.createElement("a");
      downloadLink.href = pdfUrl;
      downloadLink.download = "document.pdf";

      // Trigger a click event on the download link
      downloadLink.click();

      // Clean up the URL object
      URL.revokeObjectURL(pdfUrl);
    };

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleDeleteModalOpen = () => {
      setDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
      setDeleteModalOpen(false);
    };

    const handleDeleteDocument = async (docId, deviceId) => {
      // handle deleting of document
      console.log("Delete document");
      try {
        const response = await fetch(`/api/documents/delete/${docId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to delete");
        }

        console.log(`Successfully deleted document`);
        handleDeleteModalClose();
        location.reload();
      } catch (error) {
        console.error(error);
      }
    };

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [docSharing, setDocSharing] = useState([]);
    
    const events =useSelector((state) => {return state.events.items});
    console.log('messages table', events)

    const handleClick = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleSend = useCatch(async (doc_id) => {
      console.log("doc_id", doc_id);
      console.log(`Send email "${email}" with message "${message}"`);
      //Add document sharing preference to the database - TODO
      console.log("docSharing", docSharing);

      // try {
      const response = await fetch(`/api/documents/docShare/${doc_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ contact: email, message: message }),
      });

      if (!response.ok) {
        throw Error(await response.text());
      }
      console.log(`Mail sent with document "${email}" to successfully`);
      // } catch (error) {
      //   console.error(error);
      // }
      handleClose();
    });

    function eventsCount(deviceId){
      console.log(deviceId)
      return events.filter(event => event.deviceId === deviceId).length
    }

    function getLatestDocumentDate(deviceId) {
      console.log("getLatestDocumentDate");
      const documentsForDevice = getDocumentsForDevice(deviceId);
      let latestDate = new Date(0); // initialize latest date to epoch time (1970-01-01T00:00:00.000Z)

      documentsForDevice.forEach((document) => {
        const documentDate = new Date(document.uploadDate);
        if (documentDate > latestDate) {
          latestDate = documentDate;
        }
      });

      return latestDate;
    }

    const sortedDevices = devices.sort((a, b) => {
      const latestADate = getLatestDocumentDate(a.id);
      const latestBDate = getLatestDocumentDate(b.id);
      if (latestADate > latestBDate) {
        return -1;
      } else if (latestADate < latestBDate) {
        return 1;
      } else {
        return 0;
      }
    });

    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

    const [selectedFile,setSelectedFile] = useState(false);

    const handleFileChange = async (event, id) => {
      setSelectedFile(event.target.files);
      // const files = event.target.files;
      // console.log(files[0]);
      // if (files && files.length > 0) {
      //   const file = files[0];
      //   const formData = new FormData();
      //   formData.append("file", file);
      //   if (selectedOption === "Other") {
      //     formData.append("fileType", documentName);
      //   } else {
      //     formData.append("fileType", selectedOption);
      //   }
      //   const response = await fetch(`/api/documents/${id}/uploadweb`, {
      //     method: "POST",
      //     body: formData,
      //     // headers: {
      //     //   'fileType': selectedOption,
      //     // }
      //   });
      //   if (response.ok) {
      //     console.log("Successfully uploaded");
      //   } else {
      //     console.log(response);
      //   }
      //   handleUploadModalClose();
      // }
    };

    const handleFileUpload = async (event, id) => {
      const files = selectedFile;
      console.log(files[0]);
      if (files && files.length > 0) {
        const file = files[0];
        const formData = new FormData();
        formData.append("file", file);
        if (selectedOption === "Other") {
          formData.append("fileType", documentName);
        } else {
          formData.append("fileType", selectedOption);
        }
        const response = await fetch(`/api/documents/${id}/uploadweb`, {
          method: "POST",
          body: formData,
          // headers: {
          //   'fileType': selectedOption,
          // }
        });
        if (response.ok) {
          console.log("Successfully uploaded");
        } else {
          console.log(response);
        }
        handleUploadModalClose();
      }
    };

    const [selectedOption, setSelectedOption] = useState("Driver's License");

    const [documentName, setDocumentName] = useState("");

    const handleOptionChange = (event) => {
      console.log("selectedOption", selectedOption);
      setSelectedOption(event.target.value);
    };

    const handleOtherFileName = (event) => {
      console.log(event);
      setDocumentName(event.target.value);
    }

    const handleUploadModalOpen = (device) => {
      setSelectedDevice(device);
      setIsUploadModalOpen(true);
    };

    // useEffect(() => {
    //   console.log(selectedDevice);
    // }, [selectedDevice]);

    const handleUploadModalClose = () => {
      setIsUploadModalOpen(false);
    };

    return (
      <>
        {sortedDevices.map((device) => (
          <Accordion key={device.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${device.id}-content`}
              id={`panel-${device.id}-header`}
              style={{
                textAlign: "left",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  flex: 3,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {device.name}
              </Typography>
              <Badge badgeContent={eventsCount(device.id)} color="error" align={{vertical: 'top',horizontal: 'left'}}></Badge>
              <Button
                variant="contained"
                color="primary"
                style={{
                  flexShrink: 0,
                  flex: 3,
                  fontSize: "0.6rem",
                  margin: "5px",
                  paddingRight: "10px",
                  paddingLeft: "10px"
                }}
                onClick={() => handleUploadModalOpen(device)}
              >
                Upload Document
              </Button>
              {isUploadModalOpen && (
                <Dialog
                  open={isUploadModalOpen}
                  onClose={handleUploadModalClose}
                >
                  <Box p={2} maxWidth={400}>
                    <DialogTitle>Upload Document</DialogTitle>
                    <DialogContent>
                      <FormControl fullWidth margin="normal" required>
                        <InputLabel>Choose an option</InputLabel>
                        <Select
                          defaultValue={"Driver's License"}
                          onChange={handleOptionChange}
                        >
                          <MenuItem value={"Driver's License"}>
                            Driver's License
                          </MenuItem>
                          <MenuItem value={"Driver Files"}>
                            Driver Files
                          </MenuItem>
                          <MenuItem value={"Truck Registration"}>
                            Truck Registration
                          </MenuItem>
                          <MenuItem value={"Trailer Registration"}>
                            Trailer Registration
                          </MenuItem>
                          <MenuItem value={"Safety Inspection"}>
                            Safety Inspection
                          </MenuItem>
                          <MenuItem value={"Medical Card"}>
                            Medical Card
                          </MenuItem>
                          <MenuItem value={"Insurance Certificate"}>
                            Insurance Certificate
                          </MenuItem>
                          <MenuItem value={"Other"}>Other</MenuItem>
                        </Select>
                        {selectedOption === "Other" && (
                          <TextField
                            fullWidth
                            margin="normal"
                            label="Document Name"
                            onChange={handleOtherFileName}
                            required
                          />
                        )}
                      </FormControl>
                      <Box mt={2}>
                        <input
                          type="file"
                          onChange={(event) =>
                            handleFileChange(event, selectedDevice.uniqueId)
                          }
                        />
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleUploadModalClose} color="primary">
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!selectedFile}
                        onClick={(event) =>
                          handleFileUpload(event, selectedDevice.uniqueId)
                        }
                      >
                        Upload
                      </Button>
                    </DialogActions>
                  </Box>
                </Dialog>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <div
                id={getDocumentsForDevice(selectedDevice.id).map(
                  (document) => document.id
                )}
              />
              {/* Render the documents here */}
              <Grid container spacing={2}>
                {getDocumentsForDevice(device.id).map((document) => (
                  <Grid item lg={6} key={document.id}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      alignItems="center"
                      padding={theme.spacing(2)}
                      border={1}
                      borderColor="grey.300"
                    >
                      <Box display="flex" flexDirection="column" fullWidth>
                        <Typography variant="h5">
                          {document.fileName}
                        </Typography>
                        <Typography variant="body1">
                          <em>Expires:</em>
                        </Typography>
                        <Typography variant="body2">
                          {document.expirationDate.toString().slice(0, 10)}
                        </Typography>
                        <Typography variant="body1">
                          <em>Device ID:</em>
                        </Typography>
                        <Typography variant="body2">
                          {document.deviceId}
                        </Typography>
                      </Box>
                      <Box mt={2}>
                        <Box display="flex" flexDirection="row">
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => handleViewModalOpen(document)}
                          >
                            View
                          </Button>
                        </Box>
                        <Modal
                          open={viewModalOpen}
                          onClose={handleViewModalClose}
                        >
                          <div
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                backgroundColor: "white",
                                padding: "16px",
                                borderRadius: "8px",
                                maxWidth: "90vw",
                                maxHeight: "90vh",
                                overflow: "auto",
                              }}
                            >
                              {/* Column of buttons */}
                              <Box sx={{ flex: 1, minWidth: "200px", ml: 2 }}>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  fullWidth
                                >
                                  <Typography variant="h5">
                                    {selectedDocument.fileName}
                                  </Typography>
                                  <Typography variant="body1">
                                    <em>Expires:</em>
                                  </Typography>
                                  <Typography variant="body2">
                                    {selectedDocument.expirationDate
                                      .toString()
                                      .slice(0, 10)}
                                  </Typography>
                                  <Typography variant="body1">
                                    <em>Device ID:</em>
                                  </Typography>
                                  <Typography variant="body2">
                                    {selectedDocument.deviceId}
                                  </Typography>
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                  <div>
                                    <Button
                                      variant="contained"
                                      startIcon={<ShareIcon />}
                                      sx={{ mb: 1, width: "100%" }}
                                      onClick={handleClick}
                                    >
                                      Share Document
                                    </Button>
                                    <Dialog open={open} onClose={handleClose}>
                                      <DialogTitle>Share Document</DialogTitle>
                                      <DialogContent>
                                        <TextField
                                          autoFocus
                                          margin="dense"
                                          label="Email"
                                          type="email"
                                          fullWidth
                                          value={email}
                                          onChange={(event) =>
                                            setEmail(event.target.value)
                                          }
                                        />
                                        <TextField
                                          margin="dense"
                                          label="Message"
                                          type="text"
                                          fullWidth
                                          multiline
                                          rows={4}
                                          value={message}
                                          onChange={(event) =>
                                            setMessage(event.target.value)
                                          }
                                        />
                                      </DialogContent>
                                      <DialogActions>
                                        <Button onClick={handleClose}>
                                          Cancel
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            handleSend(selectedDocument.id)
                                          }
                                        >
                                          Send
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                  </div>

                                  <Button
                                    variant="contained"
                                    startIcon={<EditIcon />}
                                    sx={{ mb: 1, width: "100%" }}
                                    onClick={handleEditModalOpen}
                                  >
                                    Edit Expiration Date
                                  </Button>
                                  <Popover
                                    open={editModalOpen}
                                    onClose={handleEditModalClose}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        backgroundColor: "white",
                                        padding: "16px",
                                        borderRadius: "8px",
                                        maxWidth: "90vw",
                                        maxHeight: "90vh",
                                        overflow: "auto",
                                      }}
                                    >
                                      <Typography variant="h5">
                                        Edit Expiration Date
                                      </Typography>
                                      <TextField
                                        id="date"
                                        label="New Expiration Date"
                                        type="date"
                                        defaultValue={selectedDocument.expirationDate
                                          .toString()
                                          .slice(0, 10)}
                                        sx={{ width: "100%" }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        onChange={handleExpirationDateChange}
                                      />
                                      <Box mt={2}>
                                        <Button onClick={handleEditModalClose}>
                                          Cancel
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            handleEditExpirationDate(
                                              selectedDocument.id
                                            )
                                          }
                                        >
                                          Save
                                        </Button>
                                      </Box>
                                    </Box>
                                  </Popover>
                                  {/* Delete Button */}
                                  <Button
                                    variant="contained"
                                    startIcon={<GetAppIcon />}
                                    sx={{ mb: 1, width: "100%" }}
                                    onClick={() =>
                                      getFile(
                                        selectedDocument.id,
                                        selectedDocument.fileName
                                      )
                                    }
                                  >
                                    Download
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                    sx={{ width: "100%" }}
                                    onClick={handleDeleteModalOpen}
                                  >
                                    Delete Document
                                  </Button>
                                  <Popover
                                    open={deleteModalOpen}
                                    onClose={handleDeleteModalClose}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        backgroundColor: "white",
                                        padding: "16px",
                                        borderRadius: "8px",
                                        maxWidth: "90vw",
                                        maxHeight: "90vh",
                                        overflow: "auto",
                                      }}
                                    >
                                      <Typography variant="h5">
                                        Delete Document
                                      </Typography>
                                      <Typography variant="body1">
                                        Are you sure you want to delete this
                                        document?
                                      </Typography>
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        mt={2}
                                      >
                                        <Button
                                          onClick={handleDeleteModalClose}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            handleDeleteDocument(
                                              selectedDocument.id,
                                              selectedDevice.id
                                            )
                                          }
                                        >
                                          Delete
                                        </Button>
                                      </Box>
                                    </Box>
                                  </Popover>

                                  <Box mt={2}>
                                    <Button
                                      variant="outlined"
                                      sx={{
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      onClick={handleViewModalClose}
                                    >
                                      Close
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                              {/* //Conditionally render a PDF or image depending on the file type */}
                              {selectedDocument.fileName.indexOf('.pdf') > 0 ? (
                                <Box
                                  sx={{ flex: 4 }}
                                  style={{
                                    marginLeft: "1rem",
                                  }}
                                >
                                  <Document
                                    file={`/api/documents/view/${selectedDocument.id}`}
                                  >
                                    <Page pageNumber={1} />
                                  </Document>
                                </Box>
                              ) : (
                                <Box
                                  id="imageBox"
                                  sx={{ flex: 4 }}
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    style={{
                                      height: "40vi",
                                    }}
                                    src={imgBlob}
                                  />
                                </Box>
                              )}
                            </Box>
                          </div>
                        </Modal>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    );
  }

function Documents() {
  const theme = useTheme();
  const navigate = useNavigate();

  //TODO is this necessary?
  // const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   console.log(value);
  //   console.log(event);
  //   console.log(newValue);
  //   console.log("handleChange");
  //   setValue(newValue);
  // };



  return (
    <>
      {/* Search and Sort Controls */}
      {/* <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flexGrow={1} paddingRight={theme.spacing(2)}>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              placeholder="Search shipments"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>

        <Box>
          <FormControl variant="outlined">
            <Select
              value={"date"}
              onChange={() => console.log("Sorting changed")}
            >
              <MenuItem value={"date"}>Sort by Date</MenuItem>
              <MenuItem value={"status"}>Sort by Status</MenuItem>
              <MenuItem value={"destination"}>Sort by Destination</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box> */}
      {/* Tabs */}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        paddingTop={theme.spacing(2)}
      >
        <Typography variant="h5">Truck and Driver Documents</Typography>
      </Box>
      {/* Accordion Menus */}
      <Box paddingTop={theme.spacing(2)}>
        <TruckDocuments />
      </Box>
    </>
  );
}

export default Documents;
