/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import TextField from "@mui/material/TextField";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "../common/components/LocalizationProvider";
import PageLayout from "../common/components/PageLayout";
import SettingsMenu from "./components/SettingsMenu";
import { useCatch } from "../reactHelper";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-evenly",
    "& > *": {
      flexBasis: "33%",
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
  },
}));

const SharePage = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const t = useTranslation();

  const { deviceId } = useParams();
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`/api/documents/viewDeviceDocs/${deviceId}`);
      const data = await response.json();
      setDocuments(data);
    };
    fetchData();
  }, [deviceId]);

  // const tempDocuments = [
  //   "Temp1",
  //   "Temp2",
  //   "Temp3",
  //   "Temp4",
  // ];

  const documentNames = documents.map((document) => document.fileName);

  const [savedId, setSavedId] = useState(0);

  const [item, setItem] = useState({});

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const renderValue = (selected) => {
    // console.log(documents);
    console.log(documents.filter((sel) => sel.id==selected[0]));
    if (selected.length === 0) {
      return "None";
    } else if (selected.length === 1) {
      return documents.filter((sel) => sel.id==selected[0])[0].fileName;
    } else {
      return `${selected.length} selected`;
    }
  };


  const handleSelectChange = (event) => {
    // console.log(event);
    setSelectedDocuments(event.target.value);
    setItem({
      ...item,
      shareDocuments: event.target.value,
    });
    // console.log("selected documents: " + event.target.value);
    // //Set this to the state of the item
    // item.shareDocuments = event.target.value;
    // console.log(item.shareDocuments);
  };

  const handleSend = useCatch(async () => {
    let command;
    if (savedId) {
      const response = await fetch(`/api/commands/${savedId}`);
      if (response.ok) {
        command = await response.json();
      } else {
        throw Error(await response.text());
      }
    } else {
      command = item;

    }

    command.deviceId = parseInt(deviceId, 10);

    // console.log('logging shareDocs', command.shareDocuments);
    // console.log('logging contact', command.contact);
    const response = await fetch("/api/commands/share", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(command),
    });

    if (response.ok) {
      navigate(-1);
    } else {
      throw Error(await response.text());
    }
  });

  const validate = () => savedId || (item && item.contact);

  const documentNameRender = (fileNamesArray) => {
    console.log(fileNamesArray);
    if (fileNamesArray === undefined || fileNamesArray === null) {
      console.log("file array empty");
      return (<Typography>None</Typography>);
    } else {
      console.log("Files found");
      return(
        fileNamesArray.map((name) => (
          <Typography key={name}>{name}</Typography>
        ))
      );
    }
  };

  return (
    <PageLayout
      menu={<SettingsMenu />}
      breadcrumbs={["settingsTitle", "deviceCommand"]}
    >
      <Container maxWidth="xs" className={classes.container}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">{t("sharedRequired")}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <TextField
              value={item.contact || ""}
              onChange={(event) =>
                setItem({ ...item, contact: event.target.value })
              }
              label={t("userEmail")}
            />
            <div className={classes.row}>
              <FormControl fullWidth>
                <InputLabel>Share Documents</InputLabel>
                <Select
                  multiple
                  value={selectedDocuments}
                  onChange={handleSelectChange}
                  renderValue ={renderValue}
                  // handleSelectChange={handleSelectChange}
                  // renderValue={documentNameRender(documentNames)}
                >
                  {documents.map((doc) => (
                    <MenuItem key={doc.id} value={doc.id}>
                      <Checkbox
                        checked={selectedDocuments.indexOf(doc.id) > -1}
                      />
                      <Typography>{doc.fileName}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </AccordionDetails>
        </Accordion>

        <div className={classes.buttons}>
          <Button
            type="button"
            color="primary"
            variant="outlined"
            onClick={() => navigate(-1)}
          >
            {t("sharedCancel")}
          </Button>
          <Button
            type="button"
            color="primary"
            variant="contained"
            onClick={handleSend}
            disabled={!validate()}
          >
            {t("commandSend")}
          </Button>
        </div>
      </Container>
    </PageLayout>
  );
};

export default SharePage;
