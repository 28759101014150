/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShareIcon from "@mui/icons-material/Share";
import GetAppIcon from "@mui/icons-material/GetApp";
import { Document, Page } from "react-pdf";
import { useCatch } from "../../../reactHelper";
import moment from 'moment';

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  Typography,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Modal,
  Popover,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  IconButton,
  Stack,
  FormControlLabel,
  Icon,
  Paper,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { devicesActions } from "../../../store";
import { trackIdsActions } from "../../../store";
import { positionsActions } from "../../../store";
import DevicePage from "../../../settings/DevicePage";

import {
  LocalShipping as LocalShippingIcon,
  AddBox as AddBoxIcon,
  LocationOn as LocationOnIcon,
  Description as DescriptionIcon,
  Mail as MailIcon,
  Map as MapIcon,
} from "@mui/icons-material";

import DeleteIcon from "@mui/icons-material/Delete";
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';

//importing icons for different trucks
import Autocarrier from "../../../resources/images/icon/autocarrier.svg";
import Flatbed from "../../../resources/images/icon/flatbed.svg";
import Reefer from "../../../resources/images/icon/reefer.svg";
import ContainerIcon from "../../../resources/images/icon/container.svg";
import Trailer from "../../../resources/images/icon/trailer.svg";
import Jumbo from "../../../resources/images/icon/jumbo.svg";
import { width } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  statusOffline: {
    filter:
      "invert(30%) sepia(66%) saturate(2338%) hue-rotate(343deg) brightness(100%) contrast(92%)",
  },
  statusOnline: {
    filter:
      "invert(56%) sepia(69%) saturate(381%) hue-rotate(73deg) brightness(91%) contrast(91%)",
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#3373C8",
      green: "#14AE5C",
      red: "#FF0000",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
});

const getFile = (documentId, fileName) => {
  fetch(`/api/documents/view/${documentId}`)
    .then((res) => res.blob())
    .then((data) => {
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.download = fileName;
      a.click();
    });
};

function AddTruckModal(props) {
  const [trackingId, setTrackingId] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [contact, setContact] = useState("");
  const [phone, setPhone] = useState("");
  const [model, setModel] = useState("");
  const [transporterCategory, setTransporterCategory] = useState("");



  const handleConfirm = () => {
    // Call a function to save the truck information to the database
    // SEND TO BACKEND - TODO

    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Add to Shipment</DialogTitle>
      {/* //shipment Description */}
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Shipment Description
        </Typography>
      </DialogContent>
      <DialogContent>
        <TextField
          label="Origin"
          value={origin}
          onChange={(event) => setOrigin(event.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Destination"
          value={destination}
          onChange={(event) => setDestination(event.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Contact"
          value={contact}
          onChange={(event) => setContact(event.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Phone"
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button onClick={handleConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DeleteShipmentButton(props) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <Stack direction="row" alignItems="center">
        <Button
          onClick={() => setModalOpen(true)}
          variant="outlined"
          size="small"
          color="primary"
          sx={{ fontSize: "0.7rem" }}
        >
          <IconButton size="small" color="primary">
            <DeleteIcon />
          </IconButton>
          Delete Shipment
        </Button>
      </Stack>
      <DeleteShipmentModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        shipmentId={props.shipmentId}
      />
    </Box>
  );
}

function DeleteShipmentModal(props) {
  const handleConfirm = () => {
    // Call a function to save the truck information to the database
    // SEND TO BACKEND - TODO
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Delete Shipment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this shipment?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button onClick={handleConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AddShipmentButton() {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <Stack direction="row" alignItems="center">
        <Button
          onClick={handleOpenModal}
          variant="outlined"
          size="small"
          color="primary"
          sx={{ fontSize: "0.7rem" }}
        >
          <IconButton size="small" color="primary">
            <AddBoxIcon />
          </IconButton>
          Add Shipment
        </Button>
      </Stack>
      <AddTruckModal open={modalOpen} onClose={handleCloseModal} />
    </Box>
  );
}

function DeviceCard(props) {
  console.log("Devicecard");
  const [selectedDevice, setSelectedDevice] = useState({});
  // const selectedDevice = useSelector((state) => {
  //   console.log(state);
  //   if(state.devices.selectedId)
  //     return state.devices.items[state.devices.selectedId];
  //   else
  //     return null;
  // });
  const dispatch = useDispatch();

  const handleDelete = () => {
    fetch(`/api/devices/${props.trackId}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        props.onDelete(props.trackId);
      })
      .catch((error) => console.error(error));
  };

  // useEffect(() => {
  //   console.log("fetchData device");
  //   const fetchData = async () => {
  //     const response = await fetch(`/api/documents/device/${props.trackId}`);
  //     const data = await response.json();
  //     setSelectedDevice(data);
  //     dispatch(devicesActions.select(data.deviceId));
  //   };
  //   fetchData();
  // }, [props.trackId]);

  const routeToDocuments = () => {
    console.log("route to documents - 0");
  };

  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Stack key={selectedDevice.id} direction="column" spacing={2} width="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center">
          {/* // Icon for shipment based on the followign statuses: autocarrier, trailer, container, reefer, jumbo,flatbed,default=trailer */}
          {(selectedDevice.category === "autocarrier" || selectedDevice.category === "Autocarrier") && (
            <img
              src={Autocarrier}
              alt="autocarrier"
              className={
                selectedDevice.status === "offline"
              }
            />
          )}
          {(selectedDevice.category === "trailer" || selectedDevice.category === "Trailer") && (
            <img
              src={Trailer}
              alt="trailer"
              className={
                selectedDevice.status === "offline"
              }
            />
          )}
          {(selectedDevice.category === "container" || selectedDevice.category === "Container") && (
            <img
              src={ContainerIcon}
              alt="container"
              className={
                selectedDevice.status === "offline"
              }
            />
          )}
          {(selectedDevice.category === "reefer" || selectedDevice.category === "Reefer") && (
            <img
              src={Reefer}
              alt="reefer"
              className={
                selectedDevice.status === "offline"
              }
            />
          )}
          {(selectedDevice.category === "jumbo" || selectedDevice.category === "Jumbo") && (
            <img
              src={Jumbo}
              alt="jumbo"
              className={
                selectedDevice.status === "offline"
              }
            />
          )}
          {(selectedDevice.category === "flatbed" || selectedDevice.category === "Flatbed") && (
            <img
              src={Flatbed}
              alt="flatbed"
              className={
                selectedDevice.status === "offline"
              }
            />
          )}
          {![
            "autocarrier",
            "trailer",
            "container",
            "reefer",
            "jumbo",
            "flatbed",
            "Autocarrier",
            "Trailer",
            "Container",
            "Reefer",
            "Jumbo",
            "Flatbed",
          ].includes(selectedDevice.category) && (
            <img
              src={Trailer}
              alt="trailer"
              className={
                selectedDevice.status === "offline"
              }
            />
          )}
          <Typography ml={1}>#{props.id}</Typography>
        </Stack>
      </Stack>


      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack direction="row" alignItems="center">
          <PersonPinCircleIcon sx={{ fontSize: 24 }} />
          <Typography
            color="#3374c8"
            sx={{ fontSize: 16, fontWeight: "bold" }}
          >
            Origin
          </Typography>
        </Stack>
        <Typography color="#a0a0a0" sx={{ fontSize: 16 }}>{props.origin}</Typography>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack direction="row" alignItems="center">
          <LocationOnIcon sx={{ fontSize: 24 }} />
          <Typography
            color="#3374c8"
            sx={{ fontSize: 16, fontWeight: "bold" }}
          >
            Destination
          </Typography>
        </Stack>
        <Typography color="#a0a0a0" sx={{ fontSize: 16 }}>{props.destination}</Typography>
        
      </Stack>
    </Stack>
  );
}

function PastTransportations(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetch("/api/devices")
      .then((response) => response.json())
      .then((data) => setDevices(data))
      .catch((error) => console.error(error));
  }, []);

  const routeToDocuments = () => {
    console.log("route to documents clicked");
  };

  const [expanded2, setExpanded2] = useState(false);
  const handleClick2 = (trackId) => {
    console.log("set expanded");
    setExpanded2(!expanded2);
    console.log("set trackid");
    setselectedTrackId(trackId);
    console.log("get documents");
    getDocumentsForTrackId(trackId);
    console.log("set deviceId");
    var deviceId = getDeviceIdforTrackId(trackId);
    console.log("dispatchging trackActions");
    dispatch(trackIdsActions.select(trackId));
  }

  const [openAddTruck, setOpenAddTruck] = useState(false);

  const handleClickOpenAddTruck = () => {
    setOpenAddTruck(true);
  };

  const handleCloseAddTruck = () => {
    setOpenAddTruck(false);
  };

  // Functions from documents
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState({});
  const [pastTransports, setPastTransports] = useState([]);

  const [selectedDocument, setSelectedDocument] = useState({
    id: "",
    expirationDate: "",
    deviceId: "",
    fileName: "",
  });
  const [trackIds, setTrackIds] = useState([]);
  const [selectedTrackId, setselectedTrackId] = useState({});

  // const [pastTransports, setPastTransports] = useState([]);

  const userId = useSelector((state) => state.session.user.id);
  // const imBox1 = useRef(null);

  const [imgBlob, setImage] = useState("");
  const showImage = (documentId) => {
    // const imageBox = getElementById("imageBox");
    // const imageBox = React.findDOMNode(this.refs.cpDev1).value;
    fetch(`/api/documents/view/${documentId}`)
      .then((res) => res.blob())
      .then((data) => {
        setImage(URL.createObjectURL(data));
        const img = document.createElement("img");
        img.src = URL.createObjectURL(data);
        img.onload = () => {
          URL.revokeObjectURL(img.src);
        };
        // imBox1.current.appendChild(img);
        // imageBox.appendChild(img);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`/api/documents/viewAllDoc/${userId}`);
      const data = await response.json();
      setDocuments(data);
    };
    fetchData();
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`/api/devices`);
      const data = await response.json();
      console.log("set devices");
      setDevices(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`/api/documents/trackIds/${userId}`);
      const data = await response.json();
      setPastTransports(data);
    };
    fetchData();
  }, []);

  function getDocumentsForTrackId(trackId) {
    fetch(`/api/documents/getDocs/${trackId}`)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      setDocuments(data);
    })

    return documents;
  };

  function getDeviceIdforTrackId(trackId) {
    fetch(`/api/documents/device/${trackId}`)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log("dispatching devicesactions update");
      dispatch(devicesActions.update([data]));
      console.log("dispatching devicesactions select");
      dispatch(devicesActions.select(data.id));
      console.log("setselectedDevice");
      setSelectedDevice(data);
      console.log(data);

    // dispatch(devicesActions.select(deviceId));
      // console.log("dispatchging deviceactions");
      return data.id;
    })

    // return selectedDevice.id;
  };

  const handleViewModalOpen = (document) => {
    setSelectedDocument(document);
    showImage(document.id);
    setViewModalOpen(true);
  };

  useEffect(() => {
    console.log(selectedDocument);
  }, [selectedDocument]);

  const handleViewModalClose = () => {
    setViewModalOpen(false);
  };

  const [editModalOpen, setEditModalOpen] = useState(false);

  const handleEditModalOpen = () => {
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const downloadPdf = () => {
    // Create a new Blob object with the PDF data
    const pdfBlob = new Blob(
      [
        /* PDF data */
      ],
      { type: "application/pdf" }
    );

    // Create a download link for the PDF file
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfUrl;
    downloadLink.download = "document.pdf";

    // Trigger a click event on the download link
    downloadLink.click();

    // Clean up the URL object
    URL.revokeObjectURL(pdfUrl);
  };

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleDeleteDocument = async (docId, deviceId) => {
    // handle deleting of document
    try {
      const response = await fetch(`/api/documents/delete/${docId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete");
      }

      handleDeleteModalClose();
      location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [docSharing, setDocSharing] = useState([]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleSend = useCatch(async (doc_id) => {

      // try {
      const response = await fetch(`/api/documents/docShare/${doc_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ contact: email, message: message }),
      });

      if (!response.ok) {
        throw Error(await response.text());
      }
      // } catch (error) {
      //   console.error(error);
      // }
      handleClose();
    });

  const getDocumentsForDevice = (deviceId) => {
    return documents.filter(
      (document) => document.deviceId === deviceId && !document.permanent
    );
  };

  function getLatestDocumentDate(deviceId) {
    const documentsForDevice = getDocumentsForDevice(deviceId);
    let latestDate = new Date(0); // initialize latest date to epoch time (1970-01-01T00:00:00.000Z)

    documentsForDevice.forEach((document) => {
      const documentDate = new Date(document.uploadDate);
      if (documentDate > latestDate) {
        latestDate = documentDate;
      }
    });

    return latestDate;
  }

  const sortedpastTransports = pastTransports.sort((a, b) => {
    const latestADate = b.id;
    const latestBDate = a.id;
    if (latestADate < latestBDate) {
      return -1;
    } else if (latestADate > latestBDate) {
      return 1;
    } else {
      return 0;
    }
  });

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const [selectedFile, setSelectedFile] = useState(false);

  const handleFileChange = async (event, id) => {
    setSelectedFile(event.target.files);
    // const files = event.target.files;
    // if (files && files.length > 0) {
    //   const file = files[0];
    //   const formData = new FormData();
    //   formData.append("file", file);
    //   if (selectedOption === "Other") {
    //     formData.append("fileType", documentName);
    //   } else {
    //     formData.append("fileType", selectedOption);
    //   }
    //   const response = await fetch(`/api/documents/${id}/uploadweb`, {
    //     method: "POST",
    //     body: formData,
    //   });
    //   if (response.ok) {
    //   } else {
    //   }
    //   handleUploadModalClose();
    // }
  };

  const handleFileUpload = async (event, id) => {
    const files = selectedFile;
    if (files && files.length > 0) {
      const file = files[0];
      const formData = new FormData();
      formData.append("file", file);
      if (selectedOption === "Other") {
        formData.append("fileType", documentName);
      } else {
        formData.append("fileType", selectedOption);
      }
      const response = await fetch(`/api/documents/${id}/uploadweb`, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
      } else {
      }
      handleUploadModalClose();
    }
  };

  const [selectedOption, setSelectedOption] = useState("Driver Files");

  const [documentName, setDocumentName] = useState("");

  const handleOptionChange = (event) => {
    console.log("selectedOption", selectedOption);
    setSelectedOption(event.target.value);
  };

  const handleOtherFileName = (event) => {
    setDocumentName(event.target.value);
  }

  const handleUploadModalOpen = (device) => {
    setSelectedDevice(device);
    setIsUploadModalOpen(true);
  };

  useEffect(() => {
    console.log("Selected device:", selectedDevice);
    // dispatch(devicesActions.select(selectedDevice.id))
    // dispatch(devicesActions.update([selectedDevice]));
  }, [selectedDevice]);

  const handleUploadModalClose = () => {
    setIsUploadModalOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      {/* Active Shipments Header */}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography fontWeight="bold" fontSize={20}>
          Completed Shipments
        </Typography>
      </Stack>

      {/* Shipment Cards */}
        <Paper variant="outlined" square>
          <Stack direction="column">
            {/* Iterate through based on list of devices */}
            {sortedpastTransports.map((deviceTrack) => (
                <Stack
                  key={deviceTrack.trackId}
                  direction="column"
                >
                  <Button
                    key={deviceTrack.trackId}
                    //on the click of the card, expand the card but only one at a time - TODO
                    onClick={() => handleClick2(deviceTrack.trackId)}
                    variant="text"
                    sx={{
                      textTransform: "none",
                      justifyContent: "flex-start",
                      flexShrink: 0,
                    }}
                  >
                    <DeviceCard
                    //add expanded state to the card
                    expanded={deviceTrack.trackId==selectedTrackId}
                    key={deviceTrack.trackId}
                    trackId = {deviceTrack.trackId}
                    id = {deviceTrack.id}
                    origin = {deviceTrack.origin}
                    destination = {deviceTrack.destination}
                    onDelete={(id) => {
                      setTrackIds(trackIds.filter((trackId) => trackId !== id));
                    }}
                  />
                  </Button>
                  {/* Expanded section */}
                  {deviceTrack.trackId==selectedTrackId && (
                    <>
                      <Accordion key={deviceTrack.trackId}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel-${deviceTrack.trackId}-content`}
                          id={`panel-${deviceTrack.trackId}-header`}
                          style={{
                            textAlign: "left",
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={{
                              flex: 3,
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            Documents
                          </Typography>                        
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            id={documents.map(
                              (document) => document.id
                            )}
                          />
                          {/* Render the documents here */}
                          <Grid container spacing={2}>
                            {documents.map((document) => (
                              <Grid item lg={6} key={document.id}>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  padding={theme.spacing(2)}
                                  border={1}
                                  borderColor="grey.300"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    fullWidth
                                  >
                                    <Typography variant="h5">
                                      {document.fileName}
                                    </Typography>
                                    <Typography variant="body1">
                                      <em>Device ID:</em>
                                    </Typography>
                                    <Typography variant="body2">
                                      {document.deviceId}
                                    </Typography>
                                  </Box>
                                  <Box mt={2}>
                                    <Box display="flex" flexDirection="row">
                                      <Button
                                        variant="contained"
                                        size="small"
                                        onClick={() =>
                                          handleViewModalOpen(document)
                                        }
                                      >
                                        View
                                      </Button>
                                    </Box>
                                    <Modal
                                      open={viewModalOpen}
                                      onClose={handleViewModalClose}
                                    >
                                      <div
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          height: "100%",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            backgroundColor: "white",
                                            padding: "16px",
                                            borderRadius: "8px",
                                            maxWidth: "90vw",
                                            maxHeight: "90vh",
                                            overflow: "auto",
                                          }}
                                        >
                                          {/* Column of buttons */}
                                          <Box
                                            sx={{
                                              flex: 1,
                                              minWidth: "200px",
                                              ml: 2,
                                            }}
                                          >
                                            <Box
                                              display="flex"
                                              flexDirection="column"
                                              fullWidth
                                            >
                                              <Typography variant="h5">
                                                {selectedDocument.fileName}
                                              </Typography>
                                              <Typography variant="body1">
                                                <em>Device ID:</em>
                                              </Typography>
                                              <Typography variant="body2">
                                                {selectedDocument.deviceId}
                                              </Typography>
                                            </Box>
                                            <Box sx={{ mt: 2 }}>
                                              <div>
                                                <Button
                                                  variant="contained"
                                                  startIcon={<ShareIcon />}
                                                  sx={{ mb: 1, width: "100%" }}
                                                  onClick={handleClick}
                                                >
                                                  Share Document
                                                </Button>
                                                <Dialog
                                                  open={open}
                                                  onClose={handleClose}
                                                >
                                                  <DialogTitle>
                                                    Share Document
                                                  </DialogTitle>
                                                  <DialogContent>
                                                    <TextField
                                                      autoFocus
                                                      margin="dense"
                                                      label="Email"
                                                      type="email"
                                                      fullWidth
                                                      value={email}
                                                      onChange={(event) =>
                                                        setEmail(
                                                          event.target.value
                                                        )
                                                      }
                                                    />
                                                    <TextField
                                                      margin="dense"
                                                      label="Message"
                                                      type="text"
                                                      fullWidth
                                                      multiline
                                                      rows={4}
                                                      value={message}
                                                      onChange={(event) =>
                                                        setMessage(
                                                          event.target.value
                                                        )
                                                      }
                                                    />
                                                  </DialogContent>
                                                  <DialogActions>
                                                    <Button onClick={handleClose}>
                                                      Cancel
                                                    </Button>
                                                    <Button
                                                      onClick={() =>
                                                        handleSend(
                                                          selectedDocument.id
                                                        )
                                                      }
                                                    >
                                                      Send
                                                    </Button>
                                                  </DialogActions>
                                                </Dialog>
                                              </div>
                                              {/* Delete Button */}
                                              <Button
                                                variant="contained"
                                                startIcon={<GetAppIcon />}
                                                sx={{ mb: 1, width: "100%" }}
                                                onClick={() =>
                                                  getFile(
                                                    selectedDocument.id,
                                                    selectedDocument.fileName
                                                  )
                                                }
                                              >
                                                Download
                                              </Button>
                                              <Button
                                                variant="outlined"
                                                color="error"
                                                startIcon={<DeleteIcon />}
                                                sx={{ width: "100%" }}
                                                onClick={handleDeleteModalOpen}
                                              >
                                                Delete Document
                                              </Button>
                                              <Popover
                                                open={deleteModalOpen}
                                                onClose={handleDeleteModalClose}
                                              >
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    backgroundColor: "white",
                                                    padding: "16px",
                                                    borderRadius: "8px",
                                                    maxWidth: "90vw",
                                                    maxHeight: "90vh",
                                                    overflow: "auto",
                                                  }}
                                                >
                                                  <Typography variant="h5">
                                                    Delete Document
                                                  </Typography>
                                                  <Typography variant="body1">
                                                    Are you sure you want to
                                                    delete this document?
                                                  </Typography>
                                                  <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    mt={2}
                                                  >
                                                    <Button
                                                      onClick={
                                                        handleDeleteModalClose
                                                      }
                                                    >
                                                      Cancel
                                                    </Button>
                                                    <Button
                                                      onClick={() =>
                                                        handleDeleteDocument(
                                                          selectedDocument.id,
                                                          selectedDevice.id
                                                        )
                                                      }
                                                    >
                                                      Delete
                                                    </Button>
                                                  </Box>
                                                </Box>
                                              </Popover>

                                              <Box mt={2}>
                                                <Button
                                                  variant="outlined"
                                                  sx={{
                                                    width: "100%",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                  }}
                                                  onClick={handleViewModalClose}
                                                >
                                                  Close
                                                </Button>
                                              </Box>
                                            </Box>
                                          </Box>
                                          {/* Image Viewer */}

                                          {selectedDocument.fileName.indexOf('.pdf') > 0  ? (
                                            <Box
                                              sx={{ flex: 4}}
                                              style={{
                                                marginLeft: "1rem",
                                              }}
                                            >
                                              <Document
                                                file={`/api/documents/view/${selectedDocument.id}`}
                                              >
                                                <Page pageNumber={1} />
                                              </Document>
                                            </Box>
                                          ) : (
                                            <Box
                                              id="imageBox"
                                              sx={{ flex: 4 }}
                                              style={{
                                                textAlign: "center",
                                              }}
                                            >
                                              <img
                                                style={{
                                                  height: "40vi",
                                                }}
                                                src={imgBlob}
                                              />
                                            </Box>
                                          )}
                                        </Box>
                                      </div>
                                    </Modal>
                                  </Box>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}
              </Stack>
            ))}
        </Stack>
      </Paper>
    </ThemeProvider>
  );
}

export default PastTransportations;