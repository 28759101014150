/* eslint-disable */
import { React, useState } from "react";
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CreateIcon from "@mui/icons-material/Create";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FolderIcon from "@mui/icons-material/Folder";
import PersonIcon from "@mui/icons-material/Person";
import StorageIcon from "@mui/icons-material/Storage";
import BuildIcon from "@mui/icons-material/Build";
import PeopleIcon from "@mui/icons-material/People";
import TodayIcon from "@mui/icons-material/Today";
// import PublishIcon from '@mui/icons-material/Publish';
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "../../common/components/LocalizationProvider";
import {
  useAdministrator,
  useManager,
  useRestriction,
} from "../../common/util/permissions";
import useFeatures from "../../common/util/useFeatures";
import { useNavigate } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";

const MenuItem = ({ title, link, icon, selected }) => (
  <ListItemButton key={link} component={Link} to={link} selected={selected}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);

const SettingsMenu = () => {
  const t = useTranslation();
  const location = useLocation();

  const readonly = useRestriction("readonly");
  const admin = useAdministrator();
  const manager = useManager();
  const userId = useSelector((state) => state.session.user.id);

  const features = useFeatures();

  //handling logout
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleLogout = async () => {
    setAnchorEl(null);
    await fetch("/api/session", { method: "DELETE" });
    navigate("/login");
    dispatch(sessionActions.updateUser(null));
  };


  return (
    <>
      <List>
        <MenuItem
          title={t("sharedPreferences")}
          link="/settings/preferences"
          icon={<SettingsIcon />}
          selected={location.pathname === "/settings/preferences"}
        />
        {!readonly && (
          <>
            <MenuItem
              title={t("sharedNotifications")}
              link="/settings/notifications"
              icon={<NotificationsIcon />}
              selected={location.pathname.startsWith("/settings/notification")}
            />
            <MenuItem
              title={t("settingsUser")}
              link={`/settings/user/${userId}`}
              icon={<PersonIcon />}
              selected={location.pathname === `/settings/user/${userId}`}
            />
            {/* <MenuItem
              title={t("sharedGeofences")}
              link="/geofences"
              icon={<CreateIcon />}
              selected={location.pathname.startsWith("/settings/geofence")}
            /> */}
            {/* {!features.disableGroups && (
              <MenuItem
                title={t("settingsGroups")}
                link="/settings/groups"
                icon={<FolderIcon />}
                selected={location.pathname.startsWith("/settings/group")}
              />
            )} */}
            {!features.disableDrivers && (
              <MenuItem
                title={t("sharedDrivers")}
                link="/settings/drivers"
                icon={<PersonIcon />}
                selected={location.pathname.startsWith("/settings/driver")}
              />
            )}
            {!features.disableCalendars && (
              <MenuItem
                title={t("sharedCalendars")}
                link="/settings/calendars"
                icon={<TodayIcon />}
                selected={location.pathname.startsWith("/settings/calendar")}
              />
            )}
            {!features.disableComputedAttributes && (
              <MenuItem
                title={t("sharedComputedAttributes")}
                link="/settings/attributes"
                icon={<StorageIcon />}
                selected={location.pathname.startsWith("/settings/attribute")}
              />
            )}
            {!features.disableMaintenance && (
              <MenuItem
                title={t("sharedMaintenance")}
                link="/settings/maintenances"
                icon={<BuildIcon />}
                selected={location.pathname.startsWith("/settings/maintenance")}
              />
            )}
          </>
        )}
      </List>
      {manager && (
        <>
          <Divider />
          <List>
            {admin && (
              <MenuItem
                title={t("settingsServer")}
                link="/settings/server"
                icon={<StorageIcon />}
                selected={location.pathname === "/settings/server"}
              />
            )}
            <MenuItem
              title={t("settingsUsers")}
              link="/settings/users"
              icon={<PeopleIcon />}
              selected={
                location.pathname.startsWith("/settings/user") &&
                location.pathname !== `/settings/user/${userId}`
              }
            />
          </List>
        </>
      )}
      {/* <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleAccount}>
          <Typography color="textPrimary">{t("settingsUser")}</Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Typography color="error">{t("loginLogout")}</Typography>
        </MenuItem>
      </Menu> */}
      {/* //Logout button */}
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box p={2}>
        <Button
          variant="outlined"
          p={2}
          m={2}
          fullWidth
          color="error"
          startIcon={<ExitToAppIcon />}
          sx={{ fontSize: "0.7rem" }}
          onClick={() => handleLogout()}
        >
          Logout
        </Button>
      </Box>
    </>
  );
};

export default SettingsMenu;
