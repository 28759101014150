/* eslint-disable */
import { useState } from "react";
import { TextField, Box, Typography, List } from "@mui/material";
import Conversation from "./ConversationsComponent";

function Messages() {
  const [conversations, setConversations] = useState([
    {
      id: 1,
      name: "John Doe",
      lastMessage: "Hello, how are you?",
      date: "2022-02-10T12:34:56.000Z",
      messages: [
        {
          id: 1,
          text: "Hello, how are you?",
          date: "2022-02-10T12:34:56.000Z",
        },
        {
          id: 2,
          text: "I'm good, thanks. How about you?",
          date: "2022-02-10T12:35:23.000Z",
        },
        {
          id: 3,
          text: "I'm doing well, thanks.",
          date: "2022-02-10T12:36:10.000Z",
        },
      ],
      expanded: false,
    },
    {
      id: 2,
      name: "Jane Smith",
      lastMessage: "Are you available this weekend?",
      date: "2022-02-09T09:00:00.000Z",
      messages: [
        {
          id: 1,
          text: "Are you available this weekend?",
          date: "2022-02-09T09:00:00.000Z",
        },
        {
          id: 2,
          text: "I'm not sure yet. What did you have in mind?",
          date: "2022-02-09T09:01:10.000Z",
        },
      ],
      expanded: false,
    },
    
    //Other conversations can go here
  ]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredConversations = conversations.filter((conversation) => {
    const searchText = `${conversation.name} ${conversation.lastMessage} ${conversation.date}`;
    return searchText.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleConversationClick = (id) => {
    setConversations((prevConversations) => {
      return prevConversations.map((conversation) => {
        if (conversation.id === id) {
          return {
            ...conversation,
            expanded: !conversation.expanded,
          };
        }
        return conversation;
      });
    });
  };

  return (
    //Coming soon text
    <div>
      <h1>Messages</h1>
      <Box sx={{ width: "30vw", mx: "auto" }}>
        <Typography variant="body1">Coming soon...</Typography>
      </Box>
    </div>
    // <Box sx={{ width: "30vw", mx: "auto" }}>
    //   <TextField
    //     label="Search"
    //     variant="outlined"
    //     value={searchTerm}
    //     onChange={handleSearchChange}
    //     fullWidth
    //     margin="normal"
    //   />
    //   {filteredConversations.length === 0 && (
    //     <Typography variant="body1">No conversations found.</Typography>
    //   )}
    //   {filteredConversations.length > 0 && (
    //     <List>
    //       {filteredConversations.map((conversation) => (
    //         <Conversation
    //           key={conversation.id}
    //           id={conversation.id}
    //           name={conversation.name}
    //           lastMessage={conversation.lastMessage}
    //           date={conversation.date}
    //           messages={conversation.messages}
    //           expanded={conversation.expanded}
    //           handleConversationClick={handleConversationClick}
    //         />
    //       ))}
    //     </List>
    //   )}
    // </Box>
  );
}

export default Messages;
