/* eslint-disable */
import React, { useState, useRef } from "react";
import "./style.css"
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Stack,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PageLayout from "../common/components/PageLayout";
import SettingsMenu from "./components/SettingsMenu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditItemView from "./components/EditItemView";
import EditAttributesAccordion from "./components/EditAttributesAccordion";
import SelectField from "../common/components/SelectField";
import deviceCategories from "../common/util/deviceCategories";
// import LinkField from '../common/components/LinkField';
// import { prefixString } from '../common/util/stringUtils';
import { useTranslation } from "../common/components/LocalizationProvider";
import { useNavigate } from "react-router-dom";
import useDeviceAttributes from "../common/attributes/useDeviceAttributes";
import { useAdministrator } from "../common/util/permissions";
// import SettingsMenu from './components/SettingsMenu';
import useCommonDeviceAttributes from "../common/attributes/useCommonDeviceAttributes";
import { useCatch, useEffectAsync } from "../reactHelper";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";

const useStyles = makeStyles((theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    alignContent: "center",
  },

  pacContainer: {
    zIndex: 9999,
    "& .pac-container": {
      zIndex: 9999,
    },
  },
}));

const DevicePage = ({ handleCloseAddTruck }) => {
  // const DevicePage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const navigate = useNavigate();

  const admin = useAdministrator();

  const commonDeviceAttributes = useCommonDeviceAttributes(t);
  const deviceAttributes = useDeviceAttributes(t);
  const { id } = useParams();

  const [item, setItem] = useState(true);

  useEffectAsync(async () => {
    if (!item) {
      if (id) {
        const device = useSelector((state) => state.devices.items[id]);
        setItem(device);
      } else {
        setItem(true);
      }
    }
  }, [id, item, true]);

  const defaultDeviceId = useSelector((state) => state.devices.selectedId);

  const validate = () => item && item.origin && item.destination;
  console.log(item);
  console.log(validate);
  
  const handleAddDevice = useCatch(async () => {
    // try {
    setItem({ ...item, deviceId: defaultDeviceId });
    item.deviceId = defaultDeviceId;
    const response = await fetch(`/api/documents/addShipment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    if (!response.ok) {
      console.log("Failed to add device");
      throw Error(
        await "Failed to add shipment"
      );
    }
    console.log("Device added successfully");

    handleCloseAddTruck();
  });

  const originRef = useRef();
  const destRef = useRef();

  const handleOriginPlaceChanged = () => {
    const [ place ] = originRef.current.getPlaces();
    if(place) { 
        console.log(place.formatted_address)
        console.log(place.geometry.location.lat())
        console.log(place.geometry.location.lng())
        const origin = place.formatted_address
        const lat = place.geometry.location.lat()
        const lon = place.geometry.location.lng()
        setItem((item) => ({ ...item, origin: origin }));
        setItem((item) => ({ ...item, originLat: lat }));
        setItem((item) => ({ ...item, originLon: lon }));
    } 
  };

  const handleDestinationPlaceChanged = () => {
    const [ place ] = destRef.current.getPlaces();
    if(place) { 
        console.log(place.formatted_address)
        console.log(place.geometry.location.lat())
        console.log(place.geometry.location.lng())
        const destination = place.formatted_address
        const lat = place.geometry.location.lat()
        const lon = place.geometry.location.lng()
        setItem((item) => ({ ...item, destination: destination }));
        setItem((item) => ({ ...item, destinationLat: lat }));
        setItem((item) => ({ ...item, destinationLon: lon }));
    } 
  };

  return (
    // <PageLayout
    //   menu={<SettingsMenu />}
    //   breadcrumbs={["settingsTitle", "deviceCommand"]}
    // >
      <Stack
        endpoint="devices"
        item={item}
        setItem={setItem}
        validate={validate}
        style={{ alignItems: "center" }}
      >
        {item && (
          <Accordion defaultExpanded style={{ width: "50%" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {/* {t('sharedDevice')}
                 */}
                <p>Shipment Details</p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <LoadScript googleMapsApiKey="AIzaSyAaYNJedkSznD9z2-motXTVp6CSmz73DU0" libraries={["places"]} className={classes.pacContainer}>
                  <StandaloneSearchBox
                      onLoad={ref => originRef.current = ref}
                      onPlacesChanged={handleOriginPlaceChanged}
                  >
                      <TextField
                          value={item.origin}
                          label={"Origin"}
                      />
                  </StandaloneSearchBox>
              </LoadScript>
              <LoadScript googleMapsApiKey="AIzaSyAaYNJedkSznD9z2-motXTVp6CSmz73DU0" libraries={["places"]} className={classes.pacContainer}>
                <StandaloneSearchBox
                    onLoad={ref => destRef.current = ref}
                    onPlacesChanged={handleDestinationPlaceChanged}
                >
                    <TextField
                        value={item.destination}
                        label={"Destination"}
                    />
                </StandaloneSearchBox>
              </LoadScript> 
              <TextField
                value={item.name}
                onChange={(event) =>
                  setItem({ ...item, name: event.target.value })
                }
                label={"Contact Person"}
              />
              <TextField
                value={item.phone}
                onChange={(event) =>
                  setItem({ ...item, phone: event.target.value })
                }
                label={"Phone No."}
              />
              <TextField
                value={item.cargo}
                onChange={(event) =>
                  setItem({ ...item, cargo: event.target.value })
                }
                label={"Cargo Type"}
              />
              <TextField
                value={item.other}
                onChange={(event) =>
                  setItem({ ...item, other: event.target.value })
                }
                label={"Comments"}
              />

              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleAddDevice();
                    }}
                    disabled={!validate()}
                  >
                    Confirm
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      handleCloseAddTruck();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Stack>
    // </PageLayout>
  );
  DevicePage.propTypes = {
    handleCloseModal: PropTypes.func.isRequired,
  };
};

export default DevicePage;
