import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'tabs',
  initialState: {
    activeTab: 'dashboard',
  },
  reducers: {
    select(state, action) {
      state.activeTab = action.payload;
    },
  },
});

export { actions as tabsActions };
export { reducer as tabsReducer };
