/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Toolbar,
  IconButton,
  Button,
  OutlinedInput,
  InputAdornment,
  Popover,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Badge,
  AppBar,
  Typography,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ListIcon from "@mui/icons-material/ViewList";
import TuneIcon from "@mui/icons-material/Tune";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DevicesList from "./DevicesList";
import MapView from "../map/core/MapView";
import MapSelectedDevice from "../map/main/MapSelectedDevice";
import MapAccuracy from "../map/main/MapAccuracy";
import MapGeofence from "../map/main/MapGeofence";
import MapCurrentLocation from "../map/MapCurrentLocation";
import BottomMenu from "../common/components/BottomMenu";
import { useTranslation } from "../common/components/LocalizationProvider";
import PoiMap from "../map/main/PoiMap";
import MapPadding from "../map/MapPadding";
import StatusCard from "./StatusCard";
import { devicesActions } from "../store";
import { eventsActions } from "../store";
import MapDefaultCamera from "../map/main/MapDefaultCamera";
import usePersistedState from "../common/util/usePersistedState";
import MapLiveRoutes from "../map/main/MapLiveRoutes";
import { useDeviceReadonly } from "../common/util/permissions";
import MapPositions from "../map/MapPositions";
import MapDirection from "../map/MapDirection";
import MapOverlay from "../map/overlay/MapOverlay";
import MapGeocoder from "../map/geocoder/MapGeocoder";
import MapScale from "../map/MapScale";
import MapNotification from "../map/notification/MapNotification";
import EventsDrawer from "./EventsDrawer";
import useFeatures from "../common/util/useFeatures";
import LogTrackLogo from "../assets/darklogo.png";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingTop: "50px", //make modular with navbar height
  },
  sidebar: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 3,
    margin: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
    bottom: theme.dimensions.bottomBarHeight,
    transition: "transform .5s ease",
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: 0,
    },
  },
  sidebarCollapsed: {
    transform: `translateX(-${theme.dimensions.drawerWidthDesktop})`,
    marginLeft: 0,
    [theme.breakpoints.down("md")]: {
      transform: "translateX(-100vw)",
    },
  },
  toolbarContainer: {
    zIndex: 4,
  },
  notificationStyle: {
    color: "red",
    backgroundColor: "blue",
  },
  toolbar: {
    display: "flex",
    padding: theme.spacing(0, 1),
    "& > *": {
      margin: theme.spacing(0, 1),
    },
  },
  deviceList: {
    flex: 1,
    left: 0,
    bottom: 0,
  },
  statusCard: {
    position: "fixed",
    zIndex: 5,
    [theme.breakpoints.up("md")]: {
      left: `calc(50% + ${theme.dimensions.drawerWidthDesktop} / 2)`,
      bottom: theme.spacing(3),
    },
    [theme.breakpoints.down("md")]: {
      left: "50%",
      bottom: `calc(${theme.spacing(3)} + ${
        theme.dimensions.bottomBarHeight
      }px)`,
    },
    transform: "translateX(-50%)",
  },
  sidebarToggle: {
    position: "fixed",
    left: theme.spacing(35), //Need to make modular
    top: theme.spacing("91.5%"), //Need to make modular
    borderRadius: "0px",
    minWidth: 0,
    [theme.breakpoints.down("md")]: {
      left: 0,
    },
  },
  sidebarToggleText: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  sidebarToggleBg: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.6)",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  bottomMenu: {
    position: "fixed",
    left: theme.spacing(1.5), //Need to make modular
    top: theme.spacing(10), //Need to make modular
    zIndex: 4,
    justifyContent: "left",
    textAlign: "left",
    justifyItems: "left",
    alignItems: "left",
    backgroundColor: "white",
    margin: "0px",
    padding: "0px",
    // width: theme.spacing(30), //Need to make modular
  },
  filterPanel: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    width: theme.dimensions.drawerWidthTablet,
  },
  logo: {
    width: "100%",
    height: "auto",
    maxWidth: 200,
    maxHeight: 50,
    textAlign: "left",
  },
  logoContainer: {
    float: "left",
    width: "100%",
  },
  appBar: {
    boxShadow: "none",
    borderBottom: "1px solid #e0e0e0",
    width: "100%",
  },
  searchContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
  },
  navBarIconOne: {
    marginRight: "-0.5rem",
    marginLeft: "0.5rem",
    backgroundColor: "transparent",
    color: "white",
  },
  navBarIconTwo: {
    display: "flex",
    marginRight: "1.5rem",
    marginLeft: "1rem",
    backgroundColor: "transparent",
    color: "white",
  },
  popover: {
    padding: theme.spacing(2),
    // No click 
    // pointerEvents: "none",
    maxWidth: 600,
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  notification: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const MainPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const t = useTranslation();

  const deviceReadonly = useDeviceReadonly();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const phone = useMediaQuery(theme.breakpoints.down("sm"));

  const features = useFeatures();

  const [mapOnSelect] = usePersistedState("mapOnSelect", false);

  const [mapGeofences] = usePersistedState("mapGeofences", true);
  const [mapLiveRoutes] = usePersistedState("mapLiveRoutes", false);

  const selectedDeviceId = useSelector((state) => {
    console.log(state);
    return state.devices.selectedId;
  });
  const activeTab = useSelector((state) => state.tabs.activeTab);
  const positions = useSelector((state) => state.positions.items);
  const [filteredPositions, setFilteredPositions] = useState([]);
  const selectedPosition = filteredPositions.find(
    (position) => selectedDeviceId && position.deviceId === selectedDeviceId
  );

  const groups = useSelector((state) => state.groups.items);
  const devices = useSelector((state) => state.devices.items);
  const [filteredDevices, setFilteredDevices] = useState([]);

  const [filterKeyword, setFilterKeyword] = useState("");
  const [filterStatuses, setFilterStatuses] = useState([]);
  const [filterGroups, setFilterGroups] = useState([]);
  const [filterSort, setFilterSort] = usePersistedState("filterSort", "");
  const [filterMap, setFilterMap] = usePersistedState("filterMap", false);

  const filterRef = useRef();
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const [devicesOpen, setDevicesOpen] = useState(false);
  const [eventsOpen, setEventsOpen] = useState(false);

  const eventHandler = useCallback(() => setEventsOpen(true), [setEventsOpen]);
  const eventsAvailable = useSelector((state) => !!state.events.items.length);

  const handleClose = () => {
    setDevicesOpen(!devicesOpen);
  };

  useEffect(() => setDevicesOpen(desktop), [desktop]);

  const [events, setEvents] = useState({});
  const userId = useSelector((state) => state.session.user.id);

  const deleteEvent = async (eventId) => {
    // handle deleting of document
    console.log("Delete event");
    dispatch(eventsActions.delete(events.filter(event => {
          return event.id==eventId
        })));
    try {
      const response = await fetch(`/api/events/delete/${eventId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete");
      }

      console.log(`Successfully deleted event`);
      console.log(events.filter(event => {
          return event.id!==eventId
        }));
      setEvents(events.filter(event => {
          return event.id!==eventId
        }));
      // handleDeleteModalClose();
      // location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAllEvents = async (userId) => {
    // handle deleting of document
    console.log("Delete event");
    try {
      const response = await fetch(`/api/events/deleteAll/${userId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete");
      }

      console.log(`Successfully deleted all events`);
      setEvents([]);
    // handleDeleteModalClose();
    // location.reload();    
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log("print userId")
    const fetchData = async () => {
      const response = await fetch(`/api/events/allEvents/${userId}`);
      const data = await response.json();
      console.log('form data', data);
      setEvents(data);
      dispatch(eventsActions.add(data));
      handleNotificationNumberChange(data.length);
    };
    fetchData();
    console.log('events', events);

    return events
  }, [userId]);

  useEffect(() => {
    if (!desktop && mapOnSelect && selectedDeviceId) {
      setDevicesOpen(false);
    }
  }, [desktop, mapOnSelect, selectedDeviceId]);

  const onClick = useCallback(
    (_, deviceId) => {
      console.log("dispatching from mainpage");
      dispatch(devicesActions.select(deviceId));
    },
    [dispatch]
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const notificationsRef = useRef(null);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const filtered = Object.values(devices)
      .filter(
        (device) =>
          !filterStatuses.length || filterStatuses.includes(device.status)
      )
      .filter(
        (device) =>
          !filterGroups.length || filterGroups.includes(device.groupId)
      )
      .filter((device) =>
        `${device.phone} ${device.name} ${device.uniqueId}`
          .toLowerCase()
          .includes(filterKeyword.toLowerCase())
      );
    if (filterSort === "lastUpdate") {
      filtered.sort((device1, device2) => {
        const time1 = device1.lastUpdate
          ? moment(device1.lastUpdate).valueOf()
          : 0;
        const time2 = device2.lastUpdate
          ? moment(device2.lastUpdate).valueOf()
          : 0;
        return time2 - time1;
      });
    }
    setFilteredDevices(filtered);
    setFilteredPositions(
      filterMap
        ? filtered.map((device) => positions[device.id]).filter(Boolean)
        : Object.values(positions)
    );
  }, [
    devices,
    positions,
    filterKeyword,
    filterStatuses,
    filterGroups,
    filterSort,
    filterMap,
  ]);

  const [notificationNumber, setNotificationNumber] = useState(0);
  const [dashboardNotificationNumber, setDashboardNotificationNumber] = useState(0);
  const [documentNotificationNumber, setDocumentNotificationNumber] = useState(0);

  const handleNotificationNumberChange = (newNumber) => {
    console.log("notificationNumber", newNumber);
    setNotificationNumber(newNumber);
  };

  return (
    <div className={classes.root}>
      <div style={{ marginTop: "10px" }} />
      <MapView>
        <MapOverlay />
        {mapGeofences && <MapGeofence />}
        <MapAccuracy />
        {mapLiveRoutes && <MapLiveRoutes />}
        <MapPositions
          positions={filteredPositions}
          onClick={onClick}
          showStatus
        />
        {selectedPosition && selectedPosition.course && (
          <MapDirection position={selectedPosition} />
        )}
        <MapDefaultCamera />
        <MapSelectedDevice />
        <PoiMap />
      </MapView>
      <MapScale />
      <MapCurrentLocation />

      <MapGeocoder color="error" />
      {/* Overlay button to add a device
      <div
        style={{
          width: "60px",
          height: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          borderRadius: "30px",
          top: "5rem",
          right: "3rem",
          backgroundColor: "#fff",
          zIndex: 10,
          boxShadow:
            "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
          "&:hover": {
            backgroundColor: "#fafafa",
          },
        }}
      >
        <IconButton onClick={() => navigate("/settings/device")}>
          <AddIcon sx={{ fontSize: 30, color: "#3373C8" }} />
        </IconButton>
      </div> */}

      {/* {!features.disableEvents && (
        <MapNotification enabled={eventsAvailable} onClick={eventHandler} />
      )} */}
      {desktop && (
        <MapPadding left={parseInt(theme.dimensions.drawerWidthDesktop, 10)} />
      )}

      {/* Devices Button  */}
      {/* <Button
        variant="contained"
        color={phone ? "secondary" : "primary"}
        classes={{ containedPrimary: classes.sidebarToggleBg }}
        className={classes.sidebarToggle}
        onClick={handleClose}
        disableElevation
      >
        <ListIcon />
        <div className={classes.sidebarToggleText}>{t("deviceTitle")}</div>
      </Button> */}

      {/* NavBar Along the top*/}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar} disableGutters>
          {/* Back Button - obsolete */}
          {/* {!desktop && (
            <IconButton edge="start" sx={{ mr: 2 }} onClick={handleClose}>
              <ArrowBackIcon />
            </IconButton>
          )} */}

          <div className={classes.logoContainer}>
            <img src={LogTrackLogo} alt="logo" className={classes.logo} />
          </div>
          <div className={classes.searchContainer}>
            <OutlinedInput
              ref={filterRef}
              placeholder={t("sharedSearchDevices")}
              value={filterKeyword}
              onChange={(event) => setFilterKeyword(event.target.value)}
              endAdornment={
                <InputAdornment position="start">
                  <IconButton
                    size="small"
                    onClick={() => setFilterAnchorEl(filterRef.current)}
                    aria-controls="filter-popover"
                  >
                    <Badge
                      color="info"
                      variant="dot"
                      invisible={!filterStatuses.length && !filterGroups.length}
                    >
                      <TuneIcon fontSize="small" />
                    </Badge>
                  </IconButton>
                </InputAdornment>
              }
              size="small"
              fullWidth
            />
            <Popover
              id="filter-popover"
              open={!!filterAnchorEl}
              anchorEl={filterAnchorEl}
              onClose={() => setFilterAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className={classes.filterPanel}>
                <FormControl>
                  <InputLabel>{t("deviceStatus")}</InputLabel>
                  <Select
                    label={t("deviceStatus")}
                    value={filterStatuses}
                    onChange={(e) => setFilterStatuses(e.target.value)}
                    multiple
                  >
                    <MenuItem value="online">
                      {t("deviceStatusOnline")}
                    </MenuItem>
                    <MenuItem value="offline">
                      {t("deviceStatusOffline")}
                    </MenuItem>
                    <MenuItem value="unknown">
                      {t("deviceStatusUnknown")}
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel>{t("settingsGroups")}</InputLabel>
                  <Select
                    label={t("settingsGroups")}
                    value={filterGroups}
                    onChange={(e) => setFilterGroups(e.target.value)}
                    multiple
                  >
                    {Object.values(groups).map((group) => (
                      <MenuItem key={group.id} value={group.id}>
                        {group.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel>{t("sharedSortBy")}</InputLabel>
                  <Select
                    label={t("sharedSortBy")}
                    value={filterSort}
                    onChange={(e) => setFilterSort(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="">{"\u00a0"}</MenuItem>
                    <MenuItem value="lastUpdate">
                      {t("deviceLastUpdate")}
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterMap}
                        onChange={(e) => setFilterMap(e.target.checked)}
                      />
                    }
                    label={t("sharedFilterMap")}
                  />
                </FormGroup>
              </div>
            </Popover>


          <Badge badgeContent={notificationNumber} color="error">
            <IconButton
              disabled={deviceReadonly}
              className={classes.navBarIconOne}
              onClick={() => setAnchorEl(notificationsRef.current)}
              ref={notificationsRef}
              aria-controls="notifications-popover"
            >
              <NotificationsIcon />
            </IconButton>
          </Badge>

            <Popover
              id="notifications-popover"
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {/* Notifications Popup modal and styling*/}
              <div className={classes.popover}>
                <EventsDrawer
                  notificationNumber={notificationNumber}
                  onNotificationNumberChange={handleNotificationNumberChange}
                  events={events}
                  deleteEvent={deleteEvent}
                  deleteAllEvents={deleteAllEvents}
                />
              </div>
            </Popover>

            <IconButton
              // TODO: the user id Needs to be dynamic
              onClick={() => navigate("/settings/user")}
              disabled={deviceReadonly}
              className={classes.navBarIconTwo}
            >
              <ManageAccountsIcon />
            </IconButton>
          </div>
        </Toolbar>
        <div className={classes.deviceList}>
          <DevicesList devices={filteredDevices} />
        </div>
      </AppBar>

      {/* "BottomMenu" is really the side menu, it was formerly called "BottomMenu" because it was at the bottom of the screen. It is now at the side of the screen. I will wait to change the name of the component before I know I won't break everything. */}
      {desktop && (
        <div className={classes.bottomMenu}>
          <BottomMenu />
        </div>
      )}
      {/* {!features.disableEvents && (
        <EventsDrawer open={eventsOpen} onClose={() => setEventsOpen(false)} />
      )} */}
      {selectedDeviceId && (
        <div className={classes.statusCard}>
          <StatusCard
            deviceId={selectedDeviceId}
            activeTab = {activeTab}
            onClose={() => dispatch(devicesActions.select(null))}
          />
        </div>
      )}
    </div>
  );
};

export default MainPage;
