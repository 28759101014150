/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "../common/components/LocalizationProvider";
import { eventsActions } from "../store";


const useStyles = makeStyles((theme) => ({
  drawer: {
    width: theme.dimensions.eventsDrawerWidth,
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  box: {
    minWidth: "30vw", // Change this to your desired minimum width
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    flexGrow: 1,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  negative: {
    color: theme.palette.error.main,
  },
}));

const EventsDrawer = ({ open, onClose, notificationNumber, onNotificationNumberChange, events, deleteEvent, deleteAllEvents,  }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  // const events = useSelector((state) => state.events.items);
  const userId = useSelector((state) => state.session.user.id);

  useEffect(() => {
    onNotificationNumberChange(events.length);
  }, [events, onNotificationNumberChange]);


  
  return (
    <Box className={classes.box} display="flex" flexDirection="column">
      <Toolbar className={classes.toolbar} disableGutters>
        <Typography variant="h6" className={classes.title}>
          {t("reportEvents")}
        </Typography>
        <IconButton
          size="small"
          color="inherit" onClick={() => deleteAllEvents(userId)}
        >
          <DeleteIcon fontSize="small"/>
        </IconButton>
      </Toolbar>
      <List className={classes.list} dense>
        {events.map((event) => (
          <ListItem key={event.id}>
            <ListItemText primary={event.message} />
            <IconButton
              size="small"
              onClick={() => deleteEvent(event.id)}
            >
              <DeleteIcon fontSize="small" className={classes.negative}/>
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default EventsDrawer;
