/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Paper,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControlLabel,
  Stack,
} from "@mui/material";
import { LocationOn, Send } from "@mui/icons-material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Autocarrier from "../../../resources/images/icon/autocarrier.svg";
import Trailer from "../../../resources/images/icon/trailer.svg";
import ContainerIcon from "../../../resources/images/icon/container.svg";
import Reefer from "../../../resources/images/icon/reefer.svg";
import Jumbo from "../../../resources/images/icon/jumbo.svg";
import Flatbed from "../../../resources/images/icon/flatbed.svg";

function Geofencing() {
  const [location, setLocation] = useState("");
  const [radius, setRadius] = useState("");
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    fetch("/api/devices")
      .then((response) => response.json())
      .then((data) => setDevices(data))
      .catch((error) => console.error(error));
  }, []);

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleRadiusChange = (event) => {
    setRadius(event.target.value);
  };

  return (
        //Coming soon text
        <div>
        <h1>Geofences</h1>
        <Box sx={{ width: "30vw", mx: "auto" }}>
          <Typography variant="body1">Coming soon...</Typography>
        </Box>
      </div>

    // <Box sx={{ width: "100%", maxWidth: "1200px", mx: "auto" }}>
    //   <Box
    //     sx={{
    //       justifyContent: "space-between",
    //       alignItems: "center",
    //       my: 2,
    //     }}
    //   >
    //     <TextField
    //       sx={{ width: "100%" }}
    //       label="Location"
    //       value={location}
    //       onChange={handleLocationChange}
    //       InputProps={{ startAdornment: <LocationOn /> }}
    //     />
    //     <Box sx={{ display: "flex", my: 2 }}>
    //       <FormControl sx={{ width: "100%" }}>
    //         <InputLabel>Radius</InputLabel>
    //         <Select
    //           sx={{ width: "70%", display: "flex" }}
    //           value={radius}
    //           onChange={handleRadiusChange}
    //         >
    //           <MenuItem value={1}>1 mile</MenuItem>
    //           <MenuItem value={5}>5 miles</MenuItem>
    //           <MenuItem value={10}>10 miles</MenuItem>
    //         </Select>
    //       </FormControl>
    //       <Button
    //         sx={{ width: "25%" }}
    //         variant="contained"
    //         startIcon={<Send />}
    //       >
    //         Save
    //       </Button>
    //     </Box>
    //   </Box>

    //   <Box sx={{ my: 2 }}>
    //     <FormControlLabel
    //       control={<Checkbox />}
    //       label="Send me a notification"
    //     />
    //     <FormControlLabel
    //       control={<Checkbox />}
    //       label="Notify driver to upload document"
    //     />
    //   </Box>

    //   <Typography variant="h5" sx={{ my: 2 }}>
    //     Select Shipment
    //   </Typography>

    //   {devices.map((device) => (
    //     <Paper key={device.name} variant="outlined" square>
    //       <Stack direction="column" spacing={2} p={2}>
    //         <Stack
    //           direction="row"
    //           justifyContent="space-between"
    //           alignItems="center"
    //         >
    //           <Stack direction="row" alignItems="center">
    //             {/* // Icon for shipment based on the followign statuses: autocarrier, trailer, container, reefer, jumbo,flatbed,default=trailer */}
    //             {device.category === "autocarrier" && (
    //               <img src={Autocarrier} alt="autocarrier" />
    //             )}
    //             {device.category === "trailer" && (
    //               <img src={Trailer} alt="trailer" />
    //             )}
    //             {device.category === "container" && (
    //               <img src={ContainerIcon} alt="container" />
    //             )}
    //             {device.category === "reefer" && (
    //               <img src={Reefer} alt="reefer" />
    //             )}
    //             {device.category === "jumbo" && <img src={Jumbo} alt="jumbo" />}
    //             {device.category === "flatbed" && (
    //               <img src={Flatbed} alt="flatbed" />
    //             )}
    //             {![
    //               "autocarrier",
    //               "trailer",
    //               "container",
    //               "reefer",
    //               "jumbo",
    //               "flatbed",
    //             ].includes(device.category) && (
    //               <img src={Trailer} alt="trailer" />
    //             )}

    //             <Typography variant="h5" component="span" ml={1}>
    //               {device.name}
    //             </Typography>
    //           </Stack>
    //           <Box
    //             bgcolor="#14AE5C"
    //             color="white"
    //             p={1}
    //             borderRadius={1}
    //             display="flex"
    //             alignItems="center"
    //           >
    //             {device.status}
    //           </Box>
    //         </Stack>

    //         <Stack direction="row" alignItems="center">
    //           <Typography
    //             fontSize={20}
    //             color="#3374c8"
    //             display="flex"
    //             alignItems="left"
    //           >
    //             <LocationOnIcon sx={{ fontSize: 20, marginTop: "5px" }} />
    //             <strong>Destination: </strong>
    //           </Typography>
    //           <Typography ml={1}>Test destination</Typography>
    //         </Stack>
    //       </Stack>
    //     </Paper>
    //   ))}
    // </Box>
  );
}

export default Geofencing;
