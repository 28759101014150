/* eslint-disable */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Paper,
  Menu,
  MenuItem,
  Typography,
  Badge,
  Tabs,
  Tab,
  Box,
  IconButton,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import MessageIcon from "@mui/icons-material/Message";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

import { sessionActions } from "../../store";
import { eventsActions } from "../../store";
import { tabsActions } from "../../store";
import { useTranslation } from "./LocalizationProvider";
import { useRestriction } from "../util/permissions";
import AddIcon from "@mui/icons-material/Add";

import DashboardComponent from "../components/SideBarComponents/Dashboard";
import GeofencesComponent from "../components/SideBarComponents/Geofences";
import MessagesComponent from "../components/SideBarComponents/Messages";
import DocumentsComponent from "./SideBarComponents/Documents";
// import SettingsComponent from "./SideBarComponents/Settings";
import PastTransportations from "./SideBarComponents/PastTransportations";

function MyTabs() {
  //Begin Navigation Stuff
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const t = useTranslation();

  const readonly = useRestriction("readonly");
  // const disableReports = useRestriction('disableReports');
  const userId = useSelector((state) => state.session.user.id);
  const socket = useSelector((state) => state.session.socket);

  const [anchorEl, setAnchorEl] = useState(null);

  const currentSelection = () => {
    if (location.pathname === `/settings/user/${userId}`) {
      return "account";
    }
    if (location.pathname.startsWith("/settings")) {
      return "settings";
    }
    if (location.pathname.startsWith("/reports")) {
      return "reports";
    }
    if (location.pathname === "/") {
      return "map";
    }
    return null;
  };

  const handleAccount = () => {
    setAnchorEl(null);
    navigate(`/settings/user/${userId}`);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    await fetch("/api/session", { method: "DELETE" });
    navigate("/login");
    dispatch(sessionActions.updateUser(null));
  };

  const handleSelection = (event, value) => {
    switch (value) {
      case "dashboard":
        navigate("/");
        break;
      case "reports":
        navigate("/reports/route");
        break;
      case "geofences":
        navigate("/geofences");
        break;
      case "settings":
        navigate("/settings/preferences");
        break;
      case "account":
        setAnchorEl(event.currentTarget);
        break;
      case "logout":
        handleLogout();
        break;
      default:
        break;
    }
  };
  //End Navigation Stuff

  const [selectedTab, setSelectedTab] = useState("dashboard");
  const events =useSelector((state) => {return state.events.items});
  const [eventsCount, setEventsCount] = useState(events.length);
  const activeTab = useSelector((state) => {return state.activeTab});


  const handleTabChange = (event, newValue) => {
    // console.log(events);
    setEventsCount(events.length);
    setSelectedTab(newValue);
    dispatch(tabsActions.select(newValue));

  };

  const renderTabPanel = (tabValue, component) => {
    return (
      <div
        style={{
          textAlign: "left",
          alignItems: "left",
          alignContent: "left",
          justifyContent: "left",
          textAlign: "left",
          justifyItems: "left",
          width: "100%",
          padding: "0px",
          margin: "0px",
          boxShadow:
            "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
          "&:hover": {
            backgroundColor: "#fafafa",
          },
        }}
      >
        <TabPanel value={selectedTab} index={tabValue}>
          {component}
        </TabPanel>
      </div>
    );
  };

  return (
    <Box
      display="flex">
      {/* Side Menu */}
      <Tabs
        value={selectedTab}
        orientation="vertical"
        onChange={handleTabChange}
        style={{
          width: "15vw",
          backgroundColor: "#fafafa",
          justifyContent: "left",
          padding: "0px",
          margin: "0px",
        }}
      >
        <Tab
          value="dashboard"
          id="dashboard"
          label={
            <Box sx={{ display: "flex", alignItems: "center", width: "100%"}}>
              <DashboardIcon sx={{ mr: 1 }} /> <span>Dashboard</span>
            </Box>
          }
        />
        <Tab
          value="geofences"
          label={
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <AddLocationAltIcon sx={{ mr: 1 }} /> <span>Geofences</span>
            </Box>
          }
        />
        <Tab
          value="documents"
          label={
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              {/* <Badge badgeContent={eventsCount} color="error" anchorOrigin={{vertical: 'top',horizontal: 'left',}}> */}
                <DocumentScannerIcon sx={{ mr: 1 }} /> <span>Documents</span>
              {/* </Badge> */}
            </Box>
          }
        />
        <Tab
          value="messages"
          label={
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <MessageIcon sx={{ mr: 1 }} /> <span>Messages</span>
            </Box>
          }
        />
        <Tab
          value="pasttransports"
          label={
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <LocalShippingIcon sx={{ mr: 1 }} /> <span>Shipment History</span>
            </Box>
          }
        />
      </Tabs>
      <Box
        flexGrow={1}
        style={{ overflow: "auto", height: "calc(95vh - 64px)", width: "30vw" }}
      >
        {renderTabPanel("dashboard", <DashboardComponent />)}
        {renderTabPanel("geofences", <GeofencesComponent />)}
        {renderTabPanel("documents", <DocumentsComponent />)}
        {renderTabPanel("messages", <MessagesComponent />)}
        {renderTabPanel("pasttransports", <PastTransportations />)}
      </Box>
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ width: "100%", padding: "0px", margin: "0px" }}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default MyTabs;
