import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import {
  IconButton, Paper, Slider, Toolbar, Typography,
} from '@mui/material';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TuneIcon from '@mui/icons-material/Tune';
import DownloadIcon from '@mui/icons-material/Download';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MapView from '../map/core/MapView';
import MapRoutePath from '../map/MapRoutePath';
import MapPositions from '../map/MapPositions';
// import { formatTime } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    zIndex: 3,
    margin: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  title: {
    flexGrow: 1,
  },
  slider: {
    width: '100%',
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formControlLabel: {
    height: '100%',
    width: '100%',
    paddingRight: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
    },
  },
}));

const ReplayPage = () => {
  const t = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const timerRef = useRef();

  const defaultDeviceId = useSelector((state) => state.devices.selectedId);
  const selectedTrackId = useSelector((state) => state.trackIds.selectedId);

  const selectedDeviceId = defaultDeviceId;
  const from = moment().startOf('day').toISOString();
  const to = moment().endOf('day').toISOString();
  const [positions, setPositions] = useState([]);
  const [index, setIndex] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [playing, setPlaying] = useState(false);

  const deviceName = useSelector((state) => {
    if (selectedDeviceId) {
      const device = state.devices.items[selectedDeviceId];
      if (device) {
        return device.name;
      }
    }
    return null;
  });

  const onClick = useCallback((positionId) => {
    navigate(`/position/${positionId}`);
  }, [navigate]);

  useEffect(() => {
    if (playing && positions.length > 0) {
      timerRef.current = setInterval(() => {
        setIndex((index) => index + 1);
      }, 500);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [playing, positions]);

  useEffect(() => {
    if (index >= positions.length - 1) {
      clearInterval(timerRef.current);
      setPlaying(false);
    }
  }, [index, positions]);

  useEffect(() => {
    const getData = async () => {
      // setSelectedDeviceId(deviceId);
      // setFrom(from);
      // setTo(to);
      const query = new URLSearchParams({ deviceId: defaultDeviceId, from, to, selectedTrackId });
      const response = await fetch(`/api/positions?${query.toString()}`);
      if (response.ok) {
        setIndex(0);
        const positions = await response.json();
        setPositions(positions);
        if (positions.length) {
          setExpanded(false);
        } else {
          throw Error(t('sharedNoData'));
        }
      } else {
        throw Error(await response.text());
      }
    };
    getData().catch(console.error);
  }, [defaultDeviceId]);

  const handleDownload = () => {
    const query = new URLSearchParams({ deviceId: selectedDeviceId, from, to });
    window.location.assign(`/api/positions/kml?${query.toString()}`);
  };

  return (
    <div className={classes.root}>
      <MapView>
        <MapRoutePath positions={positions} />
        {index < positions.length && (
          <MapPositions positions={[positions[index]]} onClick={onClick} />
        )}
      </MapView>
      <div className={classes.sidebar}>
        <Paper elevation={3} square>
          <Toolbar>
            <IconButton edge="start" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>{t('reportReplay')}</Typography>
            {!expanded && (
              <>
                <IconButton onClick={handleDownload}>
                  <DownloadIcon />
                </IconButton>
                <IconButton edge="end" onClick={() => setExpanded(true)}>
                  <TuneIcon />
                </IconButton>
              </>
            )}
          </Toolbar>
        </Paper>
        <Paper className={classes.content} square>
          <Typography variant="subtitle1" align="center">{deviceName}</Typography>
          <Slider
            className={classes.slider}
            max={positions.length - 1}
            step={null}
            marks={positions.map((_, index) => ({ value: index }))}
            value={index}
            onChange={(_, index) => setIndex(index)}
          />
          <div className={classes.controls}>
            {`${index + 1}/${positions.length}`}
            <IconButton onClick={() => setIndex((index) => index - 1)} disabled={playing || index <= 0}>
              <FastRewindIcon />
            </IconButton>
            <IconButton onClick={() => setPlaying(!playing)} disabled={index >= positions.length - 1}>
              {playing ? <PauseIcon /> : <PlayArrowIcon /> }
            </IconButton>
            <IconButton onClick={() => setIndex((index) => index + 1)} disabled={playing || index >= positions.length - 1}>
              <FastForwardIcon />
            </IconButton>
          </div>
        </Paper>
      </div>
    </div>
  );
};

// function Replay() {
//   const theme = useTheme();

//   return (
//     <>
//       <Box
//         display="flex"
//         flexDirection="row"
//         justifyContent="space-between"
//         alignItems="center"
//         paddingTop={theme.spacing(2)}
//       >
//         <Typography variant="h5">Rollback history for Selected Device</Typography>
//       </Box>
//       {/* Accordion Menus */}
//       <Box paddingTop={theme.spacing(2)}>
//         <ReplayPage />
//       </Box>
//     </>
//   );
// }

export default ReplayPage;
