/* eslint-disable */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Stack,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PageLayout from "../common/components/PageLayout";
import SettingsMenu from "./components/SettingsMenu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditItemView from "./components/EditItemView";
import EditAttributesAccordion from "./components/EditAttributesAccordion";
import SelectField from "../common/components/SelectField";
import deviceCategories from "../common/util/deviceCategories";
// import LinkField from '../common/components/LinkField';
// import { prefixString } from '../common/util/stringUtils';
import { useTranslation } from "../common/components/LocalizationProvider";
import { useNavigate } from "react-router-dom";
import useDeviceAttributes from "../common/attributes/useDeviceAttributes";
import { useAdministrator } from "../common/util/permissions";
// import SettingsMenu from './components/SettingsMenu';
import useCommonDeviceAttributes from "../common/attributes/useCommonDeviceAttributes";
import { useCatch, useEffectAsync } from "../reactHelper";

const useStyles = makeStyles((theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    alignContent: "center",
  },
}));

const DevicePage = ({ handleCloseAddTruck }) => {
  // const DevicePage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const navigate = useNavigate();

  const admin = useAdministrator();

  const commonDeviceAttributes = useCommonDeviceAttributes(t);
  const deviceAttributes = useDeviceAttributes(t);
  const { id } = useParams();

  const [item, setItem] = useState(true);

  useEffectAsync(async () => {
    if (!item) {
      if (id) {
        const device = useSelector((state) => state.devices.items[id]);
        setItem(device);
      } else {
        setItem(true);
      }
    }
  }, [id, item, true]);

  const validate = () => item && item.uniqueId && item.name;
  console.log(item);
  console.log(validate);

  const handleAddDevice = useCatch(async () => {
    // try {
    const response = await fetch(`/api/devices`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    if (!response.ok) {
      console.log("Failed to add device");
      throw Error(
        await "Failed to add device, as this device id already exists"
      );
    }
    console.log("Device added successfully");

    handleCloseAddTruck();
  });

  return (
    // <PageLayout
    //   menu={<SettingsMenu />}
    //   breadcrumbs={["settingsTitle", "deviceCommand"]}
    // >
      <Stack
        endpoint="devices"
        item={item}
        setItem={setItem}
        validate={validate}
        style={{ alignItems: "center" }}
      >
        {item && (
          <Accordion defaultExpanded style={{ width: "50%" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {/* {t('sharedDevice')}
                 */}
                <p>Details</p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.name || ""}
                onChange={(event) =>
                  setItem({ ...item, name: event.target.value })
                }
                label={t("sharedName")}
              />
              <TextField
                value={item.uniqueId || ""}
                onChange={(event) =>
                  setItem({ ...item, uniqueId: event.target.value })
                }
                label={"Driver ID"}
              />

              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleAddDevice();
                    }}
                    disabled={!validate()}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      handleCloseAddTruck();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Stack>
    // </PageLayout>
  );
  DevicePage.propTypes = {
    handleCloseModal: PropTypes.func.isRequired,
  };
};

export default DevicePage;
