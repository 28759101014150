/* eslint-disable */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  IconButton,
  Avatar,
  Table,
  TableBody,
  Icon,
  Stack,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Button,
  Accordion,
  AccordionSummary
} from "@mui/material";

import Autocarrier from "../resources/images/icon/autocarrier.svg";
import Flatbed from "../resources/images/icon/flatbed.svg";
import Reefer from "../resources/images/icon/reefer.svg";
import Container from "../resources/images/icon/container.svg";
import Trailer from "../resources/images/icon/trailer.svg";
import Jumbo from "../resources/images/icon/jumbo.svg";

// import { Typography} from '@mui/material';
// import { TableRow} from '@mui/material';
// import { TableCell} from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ReplayIcon from "@mui/icons-material/Replay";
// import PublishIcon from '@mui/icons-material/Publish';
import ShareIcon from "@mui/icons-material/Share";
import HistoryIcon from "@mui/icons-material/History";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SettingsIcon from "@mui/icons-material/Settings";

import { useTranslation } from "../common/components/LocalizationProvider";
import { formatStatus } from "../common/util/formatter";
import RemoveDialog from "../common/components/RemoveDialog";
// import PositionValue from '../common/components/PositionValue';
import dimensions from "../common/theme/dimensions";
import { useRestriction, useDeviceReadonly } from "../common/util/permissions";
// import usePersistedState from '../common/util/usePersistedState';
// import usePositionAttributes from '../common/attributes/usePositionAttributes';
import { devicesActions, trackIdsActions } from "../store";
import { useCatch, useEffectAsync } from "../reactHelper";
import { mapIcons } from "../map/core/preloadImages";
import DevicePage from "../settings/DevicePage";
import DeviceViewPage from "../settings/DeviceViewPage";
import EditItemView from "../settings/components/EditItemView";
import ReplayPage from "../other/ReplayPage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const useStyles = makeStyles((theme) => ({
  card: {
    zIndex: 1,
    width: dimensions.popupMaxWidth,
  },
  title: {
    fontWeight: 500,
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  icon: {
    width: "25px",
    height: "25px",
    filter: "brightness(0) invert(1)",
  },
  table: {
    "& .MuiTableCell-sizeSmall": {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
  cell: {
    borderBottom: "none",
  },
  actions: {
    justifyContent: "space-between",
  },
  statusOffline: {
    filter:
      "invert(30%) sepia(66%) saturate(2338%) hue-rotate(343deg) brightness(100%) contrast(92%)",
  },
  statusOnline: {
    filter:
      "invert(56%) sepia(69%) saturate(381%) hue-rotate(73deg) brightness(91%) contrast(91%)",
  },
}));

/*
const StatusRow = ({ name, content }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.cell}>
        <Typography variant="body2">{name}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body2" color="textSecondary">{content}</Typography>
      </TableCell>
    </TableRow>
  );
};
*/

const StatusCard = ({ deviceId, activeTab, onClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();

  const readonly = useRestriction();
  const deviceReadonly = useDeviceReadonly();

  const device = useSelector((state) => {
    console.log(state);
    return state.devices.items[deviceId];
  });
  const position = useSelector((state) => state.positions.items[deviceId]);
  const selectedTrackId = useSelector((state) => state.trackIds.selectedId);
  const userId = useSelector((state) => state.session.user.id);

  // const state = useSelector(state => state)

  // const positionAttributes = usePositionAttributes(t);
  // const [positionItems] = usePersistedState('positionItems', ['speed', 'address', 'totalDistance', 'course']);
  // const [positionItems] = usePersistedState('positionItems', ['Status', 'address', 'Name', 'PlateNumber', 'TokenID', 'TruckType']);

  const [removing, setRemoving] = useState(false);

  const handleRemove = useCatch(async (removed) => {
    if (removed) {
      const response = await fetch("/api/devices");
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    }
    setRemoving(false);
  });

  const handleRemoveTransportation = useCatch(async (removed) => {
    if (removed) {
      const response = await fetch(`/api/documents/trackIds/${userId}`);
      if (response.ok) {
        dispatch(trackIdsActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    }
    setRemoving(false);
  });

  // useEffectAsync(async () => {
  //   const response = await fetch(`/api/documents/trackIds/${userId}`);
  //   if (response.ok) {
  //     dispatch(trackIdsActions.refresh(await response.json()));
  //   } else {
  //     throw Error(await response.text());
  //   }
  // }, []);

  // useEffectAsync(async () => {
  //   const response = await fetch("/api/devices");
  //   if (response.ok) {
  //     dispatch(devicesActions.refresh(await response.json()));
  //   } else {
  //     throw Error(await response.text());
  //   }
  // }, []);

  function getFile(documentId, fileName) {
    fetch(`/api/documents/view/${documentId}`)
      .then((res) => res.blob())
      .then((data) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = fileName;
        a.click();
      });
    // useCatch(async () => {
    //   const response = await fetch(`/api/documents/view/${documentId}`);
    //   if (response.ok) {
    //     // setAddress(await response.text());
    //   } else {
    //     throw Error(await response.text());
    //   }
    // });
  }

  //on click bring up the edit device page with the device id passed in as a parameter to be used to get the device data as 'item'
  function handleDeviceProperties(item) {
    return (
      <EditItemView
        endpoint="devices"
        // menu={<SettingsMenu />}
        breadcrumbs={["sharedDevice"]}
      >
        <DeviceViewPage item={item} />
      </EditItemView>
    );
  }
  
  function ReplayHistory(){
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {/* Truck Name Here */}
        <ReplayPage />
      </AccordionSummary>
    </Accordion>
  }

  //Fetch all the documents for the device and display them in a table
  const fetchDocuments = useCatch(async () => {
    const response = await fetch(`/api/documents/${deviceId}`);
    if (response.ok) {
      fullResponse = await response.json();

      fileNames = [];

      for (var i = 0; i < fullResponse.length; i++) {
        fileNames.push(fullResponse[i].fileName);
      }

      return fileNames; // This should return the file names in an array that will be put in the URL

      return await response.json();
    } else {
      throw Error(await response.text());
    }
  });

  const [openAddTruck, setOpenAddTruck] = useState(false);

  const handleClickOpenAddTruck = () => {
    setOpenAddTruck(true);
  };

  const handleCloseAddTruck = () => {
    setOpenAddTruck(false);
  };

  return (
    <>
      {device && (
        <Card elevation={3} className={classes.card}>
          <Stack
            direction="row"
            p={1}
            spacing={2}
            alignItems="center"
            justifyContent="space-around"
          >
            <Icon fontSize="large">
              {(device.category === "autocarrier" || device.category === "Autocarrier") && (
                <img
                  src={Autocarrier}
                  alt="autocarrier"
                  className={
                    device.status === "online"
                      ? classes.statusOnline
                      : classes.statusOffline
                  }
                />
              )}
              {(device.category === "trailer" || device.category === "Trailer") && (
                <img
                  src={Trailer}
                  alt="trailer"
                  className={
                    device.status === "online"
                      ? classes.statusOnline
                      : classes.statusOffline
                  }                />
              )}
              {(device.category === "container" || device.category === "Container") && (
                <img
                  src={Container}
                  alt="container"
                  className={
                    device.status === "online"
                      ? classes.statusOnline
                      : classes.statusOffline
                  }
                />
              )}
              {(device.category === "reefer" || device.category === "Reefer") && (
                <img
                  src={Reefer}
                  alt="reefer"
                  className={
                    device.status === "online"
                      ? classes.statusOnline
                      : classes.statusOffline
                  }
                />
              )}
              {(device.category === "jumbo" || device.category === "Jumbo") && (
                <img
                  src={Jumbo}
                  alt="jumbo"
                  className={
                    device.status === "online"
                      ? classes.statusOnline
                      : classes.statusOffline
                  }
                />
              )}
              {(device.category === "flatbed" || device.category === "Flatbed") && (
                <img
                  src={Flatbed}
                  alt="flatbed"
                  className={
                    device.status === "online"
                      ? classes.statusOnline
                      : classes.statusOffline
                  }
                />
              )}
              {![
                "autocarrier",
                "trailer",
                "container",
                "reefer",
                "jumbo",
                "flatbed",
                "Autocarrier",
                "Trailer",
                "Container",
                "Reefer",
                "Jumbo",
                "Flatbed",
              ].includes(device.category) && (
                <img
                  src={Trailer}
                  alt="trailer"
                  className={
                    device.status === "online"
                      ? classes.statusOnline
                      : classes.statusOffline
                  }
                />
              )}
            </Icon>

            <Typography variant="body">{device.name}</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>

          {/* //Current Location */}
          <Stack direction="row" alignItems="center" spacing={1} p={1}>
            <Stack direction="column" alignItems="flex-start" p={2}>
              {/* //TODO: Make dynamic with the correct backend data */}
              <Typography variant="body" fontWeight="bold">
                Truck: {device.category}
              </Typography>
              {/* <Typography variant="body" fontWeight="bold">
                        ETA: {device.positionId}
                      </Typography> */}
              <Typography variant="body" fontWeight="bold">
                License Plate: {device.attributes["License Plate"]}
              </Typography>
              <Typography variant="body" fontWeight="bold">
                Phone: {device.phone}
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="column" spacing={2} sx={{ width: "100%", p: 2 }}>
            {activeTab != 'pasttransports' && (
              <Button
                variant="contained"
                startIcon={<ShareIcon />}
                sx={{ fontSize: "0.7rem" }}
                onClick={() => navigate(`/settings/share/${deviceId}`)}
                disabled={
                  readonly ||
                  device.attributes["Tracking ID"] === "Idle" ||
                  !device.attributes.hasOwnProperty("Tracking ID")
                }
              >
                Share Tracking Info
              </Button>
            )}
            {activeTab === 'pasttransports' && (
              <Button
                variant="contained"
                startIcon={<HistoryIcon />}
                sx={{ fontSize: "0.7rem" }}
                onClick={() => navigate('/replay')}
              >
                Replay History 
              </Button>
            )}

            {activeTab != 'pasttransports' && (
            <Button
              variant="contained"
              startIcon={<SettingsIcon />}
              sx={{ fontSize: "0.7rem" }}
              onClick={handleClickOpenAddTruck}
              disabled={deviceReadonly}
            >
              Properties
            </Button>
            )}
            <Dialog open={openAddTruck} onClose={handleCloseAddTruck} fullWidth>
              <DialogContent>
                <DeviceViewPage item={device} handleCloseAddTruck={handleCloseAddTruck} />
              </DialogContent>
            </Dialog>

            {activeTab != 'pasttransports' && (
            <Button
              onClick={() => setRemoving(true)}
              disabled={deviceReadonly}
              variant="contained"
              color="error"
              sx={{ fontSize: "0.7rem" }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
            )}
            {activeTab === 'pasttransports' && (
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              sx={{ fontSize: "0.7rem" }}
              onClick={() => setRemoving(true)}
              disabled={deviceReadonly}
            >
              Delete Shipment
            </Button>
            )}

            {/* //modify shipment button */}
          
          </Stack>

          {/* 
          //Old code for reference
          <CardActions classes={{ root: classes.actions }} disableSpacing>
            <IconButton
              color="secondary"
              onClick={() => navigate(`/position/${position.id}`)}
              disabled={!position}
            >
              <PostAddIcon />
            </IconButton>
            <IconButton
              onClick={() => navigate("/replay")}
              disabled={!position}
            >
              <ReplayIcon />
            </IconButton>
            <IconButton
              onClick={() => navigate(`/settings/share/${deviceId}`)}
              disabled={
                readonly ||
                device.attributes["Tracking ID"] === "Idle" ||
                !device.attributes.hasOwnProperty("Tracking ID")
              }
            >
              <ShareIcon />
            </IconButton>
            <IconButton
              onClick={() => navigate(`/settings/device/${deviceId}`)}
              disabled={deviceReadonly}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => setRemoving(true)}
              disabled={deviceReadonly}
              className={classes.negative}
            >
              <DeleteIcon />
            </IconButton>
          </CardActions> */}
        </Card>
      )}
      {activeTab != 'pasttransports' && (
      <RemoveDialog
        open={removing}
        endpoint="devices"
        itemId={deviceId}
        onResult={(removed) => handleRemove(removed)}
      />
      )}
      {activeTab === 'pasttransports' && (
      <RemoveDialog
      open={removing}
      endpoint="documents"
      itemId={selectedTrackId}
      onResult={(removed) => handleRemoveTransportation(removed)}
      />
      )}
    </>
  );
};

export default StatusCard;
